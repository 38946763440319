import { readUploadedFileAsDataURL } from "@planted-solar/js-utils";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useEffect, useRef, useState } from "react";
import {
    archiveDSM,
    fetchDSMSignedDownloadUrl,
    fetchSlope,
    uploadRGBFile,
    uploadSlopeFile,
} from "../../../../api.js";
import SlopeAnalysis from "../../SlopeAnalysis/SlopeAnalysis.js";
import Map, { getMap } from "../map/Map.js";
import styles from "./dsmDetails.module.css";

export default function DSMDetails({ DSM, handleReprocess }) {
    const [showSlope, setShowSlope] = useState(false);
    const [slopeImageData, setSlopeImageData] = useState(null);
    const [lastSlopeImageUUID, setLastSlopeImageUUID] = useState(null);
    const [isReprocessing, setIsReprocessing] = useState(false);
    const reprocessButtonRef = useRef(null);
    const [isSlopeUploading, setIsSlopeUploading] = useState(false);
    const [isRGBUploading, setIsRGBUploading] = useState(false);

    const reprocessDSM = async (DSM) => {
        setIsReprocessing(true);
        let previousShowSlope = showSlope;
        setShowSlope(false);
        await handleReprocess(DSM);
        setIsReprocessing(false);
        setShowSlope(previousShowSlope);
    };
    const loadSlopeData = async (DSM) => {
        if (DSM !== undefined && DSM.uuid !== undefined) {
            try {
                const blob = await fetchSlope(DSM.uuid);
                const imageData = await readUploadedFileAsDataURL(blob);
                setLastSlopeImageUUID(DSM.uuid);
                setSlopeImageData(imageData);
            } catch (e) {
                console.error("Error downloading slope data.");
            }
        } else {
            setSlopeImageData(null);
        }
    };

    useEffect(() => {
        setShowSlope(false);
    }, [DSM]);

    useEffect(() => {
        let map = getMap();
        // load DSM slope image on first load, or when DSM changes
        if (
            (slopeImageData == null && DSM) ||
            (slopeImageData !== null && lastSlopeImageUUID !== DSM.uuid)
        ) {
            loadSlopeData(DSM);
            // if we need to hide it, hide it
        } else if (slopeImageData != null && !showSlope && map) {
            if (map.getLayer("slope-image-layer")) {
                map.removeLayer("slope-image-layer");
            }
        }
    }, [showSlope, DSM, slopeImageData, lastSlopeImageUUID]);

    const handleArchive = async (uuid) => {
        try {
            await archiveDSM(uuid);
            window.location.reload();
        } catch (error) {
            console.error("Error archiving DSM:", error);
        }
    };
    const handleDownload = async () => {
        if (!DSM.dsm_location) {
            alert("COG location is not available for this DSM");
            return;
        }
        try {
            const signedURL = await fetchDSMSignedDownloadUrl(DSM.dsm_location);
            window.open(signedURL.signed_url, "_blank");
        } catch (error) {
            console.error("Error fetching the signed URL:", error);
        }
    };

    const handleReplaceSlopeData = async () => {
        // Create an input element
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = ".tiff, .tif"; // Only accept .tiff and .tif files

        // Handle file selection
        fileInput.onchange = async (e) => {
            // Get the file list
            const file = e.target.files[0];
            if (file) {
                setIsSlopeUploading(true);
                // Do something with the file
                try {
                    await uploadSlopeFile(DSM.uuid, file);
                    setIsSlopeUploading(false);
                } catch (e) {
                    setIsSlopeUploading(false);
                }
            }
        };
        // Simulate a click on the input
        fileInput.click();
    };
    const handleUploadRGBData = async () => {
        // Create an input element
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = ".tiff, .tif"; // Only accept .tiff and .tif files

        // Handle file selection
        fileInput.onchange = async (e) => {
            // Get the file list
            const file = e.target.files[0];
            if (file) {
                setIsRGBUploading(true);
                // Do something with the file
                try {
                    await uploadRGBFile(DSM.uuid, file);
                    setIsRGBUploading(false);
                } catch (e) {
                    setIsRGBUploading(false);
                }
            }
        };
        // Simulate a click on the input
        fileInput.click();
    };

    return (
        <>
            {DSM ?
                <div className={styles.grid}>
                    <>
                        <h2 className={styles.title}>{DSM.name}</h2>
                        <div className={styles.dsmMetaData}>
                            <h3>Metadata</h3>
                            <table className={styles.dsmDetailTable}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <strong>Resolution (meters)</strong>
                                        </td>
                                        <td>{DSM.cell_size_m}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>UUID</strong>
                                        </td>
                                        <td>{DSM.uuid}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Created at</strong>
                                        </td>
                                        <td>{DSM.created_at}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Created by</strong>
                                        </td>
                                        <td>{DSM.created_by}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>

                    <div className={styles.slopeAnalysis}>
                        <div className={styles.optionsGrid}>
                            <button
                                className={styles.downloadButton}
                                onClick={handleDownload}
                            >
                                Download
                            </button>
                            <button
                                className={styles.reprocessButton}
                                ref={reprocessButtonRef}
                                onClick={() => reprocessDSM(DSM)}
                                disabled={isReprocessing}
                            >
                                Reprocess
                            </button>

                            <button
                                className={styles.archiveButton}
                                onClick={() => handleArchive(DSM.uuid)}
                            >
                                Archive
                            </button>
                        </div>
                        <div className={styles.uploadGrid}>
                            <button
                                className={`${styles.uploadRGB} ${isRGBUploading ? styles.loading : ""}`}
                                onClick={
                                    !isRGBUploading ? handleUploadRGBData : () => {}
                                }
                                disabled={isRGBUploading}
                            >
                                {isRGBUploading ?
                                    "Processing..."
                                :   "Upload RGB Imagery"}
                            </button>
                            <button
                                className={`${styles.uploadSlope} ${isSlopeUploading ? styles.loading : ""}`}
                                onClick={
                                    !isSlopeUploading ? handleReplaceSlopeData : (
                                        () => {}
                                    )
                                }
                                disabled={isSlopeUploading}
                            >
                                {isSlopeUploading ?
                                    "Processing..."
                                :   "Replace Slope Data"}
                            </button>
                        </div>
                        <input
                            type="checkbox"
                            className={styles.toggleSlope}
                            id="showSlope"
                            checked={showSlope}
                            onChange={() => setShowSlope((prevState) => !prevState)}
                        />
                        <label htmlFor="showSlope">Show Slope</label>

                        {showSlope && (
                            <SlopeAnalysis
                                map={getMap()}
                                DSM={DSM}
                                slopeImageData={slopeImageData}
                            />
                        )}
                    </div>

                    <Map className={styles.mapComponent} DSM={DSM} />
                </div>
            :   <div className={styles.noSelectedDSM}>
                    <strong>
                        <h2>Select a DSM to see details</h2>
                    </strong>
                </div>
            }
        </>
    );
}
