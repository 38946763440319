// src/partFields.ts
import { array, boolean, number, object, string } from "yup";

// src/units.ts
var UNIT_TYPE = /* @__PURE__ */ ((UNIT_TYPE2) => {
  UNIT_TYPE2[UNIT_TYPE2["STRING"] = 0] = "STRING";
  UNIT_TYPE2[UNIT_TYPE2["URL"] = 1] = "URL";
  UNIT_TYPE2[UNIT_TYPE2["BOOLEAN"] = 2] = "BOOLEAN";
  UNIT_TYPE2[UNIT_TYPE2["INTEGER"] = 3] = "INTEGER";
  UNIT_TYPE2[UNIT_TYPE2["METERS"] = 4] = "METERS";
  UNIT_TYPE2[UNIT_TYPE2["MILLIMETERS"] = 5] = "MILLIMETERS";
  UNIT_TYPE2[UNIT_TYPE2["FEET"] = 6] = "FEET";
  UNIT_TYPE2[UNIT_TYPE2["VOLTS"] = 7] = "VOLTS";
  UNIT_TYPE2[UNIT_TYPE2["AMPS"] = 8] = "AMPS";
  UNIT_TYPE2[UNIT_TYPE2["KILOVOLT_AMPS"] = 9] = "KILOVOLT_AMPS";
  UNIT_TYPE2[UNIT_TYPE2["PERCENTAGE"] = 10] = "PERCENTAGE";
  UNIT_TYPE2[UNIT_TYPE2["PERCENTAGE_PER_DEGREE_CELSIUS"] = 11] = "PERCENTAGE_PER_DEGREE_CELSIUS";
  UNIT_TYPE2[UNIT_TYPE2["DEGREES"] = 12] = "DEGREES";
  UNIT_TYPE2[UNIT_TYPE2["DEGREES_CELSIUS"] = 13] = "DEGREES_CELSIUS";
  UNIT_TYPE2[UNIT_TYPE2["VEC3_2"] = 14] = "VEC3_2";
  UNIT_TYPE2[UNIT_TYPE2["VEC3_4"] = 15] = "VEC3_4";
  UNIT_TYPE2[UNIT_TYPE2["METER_XYZ_2"] = 16] = "METER_XYZ_2";
  UNIT_TYPE2[UNIT_TYPE2["METER_XYZ_4"] = 17] = "METER_XYZ_4";
  UNIT_TYPE2[UNIT_TYPE2["WATTS"] = 18] = "WATTS";
  UNIT_TYPE2[UNIT_TYPE2["CELL_TECH"] = 19] = "CELL_TECH";
  return UNIT_TYPE2;
})(UNIT_TYPE || {});
var UNIT_TYPE_DISPLAY_TEXT = {
  [0 /* STRING */]: "Text",
  [1 /* URL */]: "URL",
  [2 /* BOOLEAN */]: "True/False",
  [3 /* INTEGER */]: "Number",
  [4 /* METERS */]: "Meters (m)",
  [7 /* VOLTS */]: "Volts (V)",
  [8 /* AMPS */]: "Amps (A)",
  [9 /* KILOVOLT_AMPS */]: "Kilovolt Amps (kVA)",
  [10 /* PERCENTAGE */]: "Percentage (%)",
  [11 /* PERCENTAGE_PER_DEGREE_CELSIUS */]: "Percentage Per Deg. Celsius (% / \xB0 C)",
  [12 /* DEGREES */]: "Degrees (\xB0)",
  [13 /* DEGREES_CELSIUS */]: "Degrees Celsius (\xB0C)",
  [14 /* VEC3_2 */]: "XYZ Vector (x2)",
  [15 /* VEC3_4 */]: "XYZ Vector (x4)",
  [16 /* METER_XYZ_2 */]: "XYZ Point (m) (x2)",
  [17 /* METER_XYZ_4 */]: "XYZ Point (m) (x4)",
  [18 /* WATTS */]: "Watts (W)",
  [19 /* CELL_TECH */]: "Cell Technology"
};
var CONVERSION_OPTIONS = {
  [0 /* STRING */]: [],
  [1 /* URL */]: [],
  [2 /* BOOLEAN */]: [],
  [3 /* INTEGER */]: [],
  [19 /* CELL_TECH */]: [],
  [4 /* METERS */]: [
    {
      unitDisplay: "m",
      unitName: "Meters",
      conversionFactor: 1,
      unitType: 4 /* METERS */
    },
    {
      unitDisplay: "mm",
      unitName: "Millimeters",
      conversionFactor: 1e-3,
      unitType: 5 /* MILLIMETERS */
    },
    {
      unitDisplay: "ft",
      unitName: "Feet",
      conversionFactor: 0.3048,
      unitType: 6 /* FEET */
    }
  ],
  [7 /* VOLTS */]: [
    {
      unitDisplay: "V",
      unitName: "Volts",
      conversionFactor: 1,
      unitType: 7 /* VOLTS */
    }
  ],
  [8 /* AMPS */]: [
    {
      unitDisplay: "A",
      unitName: "Amps",
      conversionFactor: 1,
      unitType: 8 /* AMPS */
    }
  ],
  [9 /* KILOVOLT_AMPS */]: [
    {
      unitDisplay: "kVA",
      unitName: "Kilovolt Amps",
      conversionFactor: 1,
      unitType: 9 /* KILOVOLT_AMPS */
    }
  ],
  [10 /* PERCENTAGE */]: [
    {
      unitDisplay: "%",
      unitName: "Percentage",
      conversionFactor: 0.01,
      unitType: 10 /* PERCENTAGE */
    }
  ],
  [11 /* PERCENTAGE_PER_DEGREE_CELSIUS */]: [
    {
      unitDisplay: "%/\xB0C",
      unitName: "Percentage/Degree Celsius",
      conversionFactor: 1,
      unitType: 11 /* PERCENTAGE_PER_DEGREE_CELSIUS */
    }
  ],
  [12 /* DEGREES */]: [
    {
      unitDisplay: "\xB0",
      unitName: "Degrees",
      conversionFactor: 1,
      unitType: 12 /* DEGREES */
    }
  ],
  [13 /* DEGREES_CELSIUS */]: [
    {
      unitDisplay: "\xB0C",
      unitName: "Degrees Celsius",
      conversionFactor: 1,
      unitType: 13 /* DEGREES_CELSIUS */
    }
  ],
  [14 /* VEC3_2 */]: [],
  [15 /* VEC3_4 */]: [],
  [16 /* METER_XYZ_2 */]: [],
  [17 /* METER_XYZ_4 */]: [],
  [18 /* WATTS */]: [
    {
      unitDisplay: "W",
      unitName: "Watts",
      conversionFactor: 1,
      unitType: 18 /* WATTS */
    }
  ]
};
var CELL_TECH_OPTIONS = [
  { value: "mono", display: "Monocrystalline" },
  { value: "multi", display: "Polycrystalline" },
  { value: "cis", display: "CIS - Copper indium di-selenide" },
  { value: "cigs", display: "CIGS - Copper indium gallium selenide" },
  { value: "cdte", display: "CdTe/Thin Film" },
  { value: "amor", display: "Amorphous" }
];

// src/partFields.ts
var PartType = /* @__PURE__ */ ((PartType2) => {
  PartType2["MODULE"] = "MODULE";
  PartType2["INVERTER"] = "INVERTER";
  PartType2["POST"] = "POST";
  PartType2["BERMUDA_HAT"] = "BERMUDA_HAT";
  PartType2["ALFALFA_HAT"] = "ALFALFA_HAT";
  return PartType2;
})(PartType || {});
var HatType = /* @__PURE__ */ ((HatType2) => {
  HatType2["BERMUDA"] = "BERMUDA";
  HatType2["ALFALFA"] = "ALFALFA";
  return HatType2;
})(HatType || {});
var POST_PART_FIELDS = [
  {
    key: "name",
    required: true,
    displayText: "Name",
    unit: 0 /* STRING */,
    tooltip: "Post Display Name",
    placeholder: "PlantedPost001"
  },
  {
    key: "length_m",
    required: true,
    displayText: "Length",
    unit: 4 /* METERS */,
    tooltip: "Length of post tube from end to end (not including tip attachment)",
    placeholder: 1.234,
    min: 0
  },
  {
    key: "diameter_m",
    required: true,
    displayText: "Diameter",
    unit: 4 /* METERS */,
    tooltip: "Outer diameter of round post",
    placeholder: 0.05,
    min: 0
  }
];
var MODULE_PART_FIELDS = [
  {
    key: "manufacturer_name",
    required: true,
    displayText: "Manufacturer Name",
    unit: 0 /* STRING */,
    // TODO: It would be nice to have this auto suggest from existing inverter manufacturer names to make getting exact matches easier.
    tooltip: "Module Manufacturer Name (ideally use the same text here as other modules with the same manufacturer)",
    placeholder: "ABC Solar Co."
  },
  {
    key: "product_name",
    required: true,
    displayText: "Product Name",
    unit: 0 /* STRING */,
    tooltip: "Module product name (try to include the full product/model name, including any modifiers indicating what power the module outputs, or if it's bifacial, etc.",
    placeholder: "ZXM6-NH144"
  },
  {
    key: "power_w",
    required: true,
    displayText: "Power",
    unit: 18 /* WATTS */,
    tooltip: "Nameplate Power of the Module in Watts",
    placeholder: "ZXM6-NH144"
  },
  {
    key: "datasheet_url",
    required: true,
    displayText: "Datasheet URL",
    unit: 1 /* URL */,
    tooltip: "URL to Datasheet used to find part values",
    placeholder: "www.modules.com/datasheet.pdf"
  },
  {
    key: "length_m",
    required: true,
    displayText: "Length",
    unit: 4 /* METERS */,
    tooltip: "Length of the long side of the module",
    placeholder: 2.222,
    min: 0
  },
  {
    key: "width_m",
    required: true,
    displayText: "Width",
    unit: 4 /* METERS */,
    tooltip: "Width of the shorter side of the module",
    placeholder: 1.111,
    min: 0
  },
  {
    key: "thickness_m",
    displayText: "Thickness",
    unit: 4 /* METERS */,
    tooltip: "Thickness of the module when laid flat",
    placeholder: 0.02,
    min: 0
  },
  {
    key: "edge2mount_x_m",
    displayText: "Edge To Mount (X)",
    unit: 4 /* METERS */,
    tooltip: "Distance from the bottom of the module along the long side from the edge to the mounting hole (usually about 1/4 of length)",
    placeholder: 0.456,
    min: 0
  },
  {
    key: "edge2mount_y_m",
    displayText: "Edge To Mount (Y)",
    unit: 4 /* METERS */,
    tooltip: "Distance from the frame edge of the long side of the module to the mounting hole",
    placeholder: 5e-3,
    min: 0
  },
  {
    key: "slot_length_m",
    displayText: "Slot Length",
    unit: 4 /* METERS */,
    tooltip: "Length of the mounting slot along the module frame (use mounting hole diameter if module has a mounting hole instead of a slot)",
    placeholder: 0,
    min: 0
  },
  {
    key: "i_mp",
    displayText: "Current at Max Power (i_mp)",
    unit: 8 /* AMPS */,
    placeholder: 5,
    min: 0
  },
  {
    key: "v_mp",
    displayText: "Voltage at Max Power (v_mp)",
    unit: 7 /* VOLTS */,
    placeholder: 5,
    min: 0
  },
  {
    key: "i_sc",
    displayText: "Short Circuit Current (i_sc)",
    unit: 8 /* AMPS */,
    placeholder: 5,
    min: 0
  },
  {
    key: "v_oc",
    displayText: "Open Circuit Voltage (v_oc)",
    unit: 7 /* VOLTS */,
    placeholder: 5,
    min: 0
  },
  {
    key: "temp_coefficient_i_sc",
    displayText: "Temperature Coefficient for Short Circuit Current",
    tooltip: "Sometimes listed as \u03B1Isc (alpha of short circuit current)",
    unit: 11 /* PERCENTAGE_PER_DEGREE_CELSIUS */,
    placeholder: 5
  },
  {
    key: "temp_coefficient_v_oc",
    displayText: "Temperature Coefficient for Open Circuit Voltage",
    tooltip: "Sometimes listed as \u03B2Voc (beta of open circuit voltage)",
    unit: 11 /* PERCENTAGE_PER_DEGREE_CELSIUS */,
    placeholder: 5
  },
  {
    key: "temp_coefficient_p_mp",
    displayText: "Temperature Coefficient at Power Max",
    tooltip: "Sometimes listed as \u03B3Pmax (gamma of max power)",
    unit: 11 /* PERCENTAGE_PER_DEGREE_CELSIUS */,
    placeholder: 5
  },
  {
    key: "nominal_operating_temp_c",
    displayText: "Nominal Operating Temperature (NOCT)",
    tooltip: "If the datasheet shows a \xB1 range, use the middle value. Usually around 43-46\xB0C",
    unit: 13 /* DEGREES_CELSIUS */,
    placeholder: 43
  },
  {
    key: "cell_tech",
    displayText: "Cell Technology",
    tooltip: `One of the following values: ${CELL_TECH_OPTIONS.map((option) => `"${option.value}" (${option.display})`).join(", ")}.
Use "mono" if unsure`,
    unit: 19 /* CELL_TECH */,
    placeholder: "mono"
  },
  {
    key: "cells_in_series",
    displayText: "Number of Cells in Series",
    tooltip: "Number of cells wired together in one of the series strings from the positive to negative pole of the module. Usually under 'cells/cell count' in the datasheet. If only the total cell count is listed, divide by 2 if the module uses standard half-cut cells, (e.g 144 => 72), and if the series groupings are listed (often in the format '144 (6x24)'), use the total number of cells for one half of the module (6x24 => 24 + 24 + 24 = 72). V_oc / this cell number should approximately equal 0.69V (within 0.025V).",
    unit: 3 /* INTEGER */,
    placeholder: 60
  }
];
var INVERTER_PART_FIELDS = [
  {
    key: "model_name",
    required: true,
    displayText: "Model Name",
    unit: 0 /* STRING */,
    tooltip: "Inverter Model Name + [Voltage output]",
    placeholder: "PlantedInverter001ABC [999]"
  },
  {
    key: "manufacturer_name",
    required: true,
    displayText: "Manufacturer Name",
    unit: 0 /* STRING */,
    // TODO: It would be nice to have this auto suggest from existing inverter manufacturer names to make getting exact matches easier.
    tooltip: "Inverter Manufacturer Name (ideally use the same text here as other inverters with the same manufacturer)",
    placeholder: "ABC Solar Co."
  },
  {
    key: "datasheet_url",
    required: true,
    displayText: "Datasheet URL",
    unit: 1 /* URL */,
    tooltip: "URL to Datasheet used to find part values",
    placeholder: "www.inverters-r-us.com/datasheet.pdf"
  },
  {
    key: "length_m",
    required: true,
    displayText: "Length",
    unit: 4 /* METERS */,
    tooltip: "Length of inverter",
    placeholder: 1.2,
    min: 0
  },
  {
    key: "width_m",
    required: true,
    displayText: "Width",
    unit: 4 /* METERS */,
    tooltip: "Width of inverter",
    placeholder: 1.2,
    min: 0
  },
  {
    key: "height_m",
    required: true,
    displayText: "Height",
    unit: 4 /* METERS */,
    tooltip: "Height of inverter",
    placeholder: 1.2,
    min: 0
  },
  {
    key: "num_mppt",
    required: true,
    displayText: "Num. MPPT",
    unit: 3 /* INTEGER */,
    tooltip: "Number of MPPT ports on the inverter",
    placeholder: 1,
    min: 0
  },
  {
    key: "shared_power",
    required: true,
    displayText: "Shared Power?",
    unit: 2 /* BOOLEAN */,
    tooltip: "True if the inverter uses shared power, false otherwise",
    placeholder: false
  },
  {
    key: "high_mppt_v",
    required: true,
    displayText: "High MPPT Voltage",
    unit: 7 /* VOLTS */,
    tooltip: "The upper limit of input voltage for the inverter's MPPT",
    placeholder: 10,
    min: 0
  },
  {
    key: "low_mppt_v",
    required: true,
    displayText: "Low MPPT Voltage",
    unit: 7 /* VOLTS */,
    tooltip: "The lower limit of input voltage for the inverter's MPPT",
    placeholder: 10,
    min: 0
  },
  {
    key: "power_consumed_night_w",
    required: true,
    displayText: "Power Consumed at Night (Tare Loss)",
    unit: 18 /* WATTS */,
    tooltip: "Amount of power consumed by the inverter when in standby, sometimes listed as 'tare' on datasheet. Estimate as (0.00025 * inverter max AC power) if not present on datasheet.",
    placeholder: 10,
    min: 0
  },
  {
    key: "power_consumed_startup_w",
    required: true,
    displayText: "Power Consumed During Startup",
    unit: 18 /* WATTS */,
    tooltip: "Amount of power consumed by the inverter when starting up. Estimate with (0.08 * inverter max AC power) if not present on datasheet.",
    placeholder: 10,
    min: 0
  },
  {
    key: "max_input_v_oc",
    required: true,
    displayText: "Max Open Circuit Voltage",
    unit: 7 /* VOLTS */,
    tooltip: "Maximum allowed open circuit voltage for inputs to the inverter. Use upper MPPT range (High MPPT Voltage) if not explicitly named on the datasheet.",
    placeholder: 10,
    min: 0
  },
  {
    key: "max_output_ac_kva",
    required: true,
    displayText: "Max AC Output",
    unit: 9 /* KILOVOLT_AMPS */,
    tooltip: "Nameplate AC power output for the inverter",
    placeholder: 10,
    min: 0
  },
  {
    key: "nominal_efficiency",
    required: true,
    displayText: "Nominal Efficiency",
    unit: 10 /* PERCENTAGE */,
    tooltip: "Percentage of DC power inverter can convert to AC power in normal operation. Weighted efficiency is fine to use here if nominal efficiency isn't present on the datasheet.",
    placeholder: 0.99,
    max: 1,
    min: 0
  },
  {
    key: "num_dc_input_ports",
    required: true,
    displayText: "Num. DC Input Ports",
    unit: 3 /* INTEGER */,
    tooltip: "Number of ports for DC input on the inverter",
    placeholder: 2,
    min: 0
  },
  {
    key: "dc_input_max_voltage",
    required: true,
    displayText: "DC Input Max Voltage",
    unit: 7 /* VOLTS */,
    tooltip: "Max DC input voltage",
    placeholder: 2,
    min: 0
  },
  {
    key: "dc_input_max_continuous_current",
    required: true,
    displayText: "DC Input Max Continuous Current",
    unit: 8 /* AMPS */,
    tooltip: "Max continuous current for DC inputs",
    placeholder: 2,
    min: 0
  }
];
var BERMUDA_HAT_PART_FIELDS = [
  {
    key: "name",
    required: true,
    displayText: "Name",
    unit: 0 /* STRING */,
    tooltip: "Hat Display Name",
    placeholder: "PlantedHat001"
  },
  {
    key: "mount_points_m",
    required: true,
    displayText: "Module Mounting Points (m)",
    unit: 16 /* METER_XYZ_2 */,
    tooltip: "The locations of the bottom of the mounting holes on modules relative to the top of the post (with the post top's center represented as 0,0,0), in meters. First point is for the 'top' module when looking top down, second is for the 'bottom' (for a nominal east/west aligned array) . Assume that the post attached to the hat is oriented along a vector straight down (0, 0, -1)",
    default: [
      [0, 0, 0],
      [0, 0, 0]
    ]
  },
  {
    key: "points_of_rotation_undulation_m",
    required: true,
    displayText: "Points Of Rotation During Undulation (m)",
    unit: 16 /* METER_XYZ_2 */,
    tooltip: `Undulation will try to keep this point on the bracket \u201Cconnected to\u201D the point on the module that starts out \u201Cconnected to\u201D this point. Defined relative to post center in the same coordinate space as the module mount points`,
    default: [
      [0, 0, 0],
      [0, 0, 0]
    ]
  },
  {
    key: "mount_up_vectors",
    required: true,
    displayText: "Mount Up Vectors",
    unit: 14 /* VEC3_2 */,
    tooltip: "Two vectors equal to the normal vectors that extend upward from the planes of connected modules. First point is for the 'top' module when looking top down, second is for the 'bottom' (for a nominal east/west aligned array). Vectors are relative to an vector representing a post pointed straight into the ground (0, 0, -1) - even if this hat is intended for a tilted post.",
    default: [
      [0, 0, 0],
      [0, 0, 0]
    ]
  },
  {
    key: "module_tilt_deg",
    required: true,
    displayText: "Module Tilt",
    unit: 12 /* DEGREES */,
    tooltip: "Intended amount of module tilt for this hat",
    placeholder: 10,
    max: 89,
    min: 0
  },
  {
    key: "post_tilt_deg_about_width_dir",
    required: true,
    displayText: "Post Tilt",
    unit: 12 /* DEGREES */,
    tooltip: "Intended post tilt for this hat",
    placeholder: 0,
    max: 89,
    min: 0
  }
];
var ALFALFA_HAT_PART_FIELDS = [
  {
    key: "name",
    required: true,
    displayText: "Name",
    unit: 0 /* STRING */,
    tooltip: "Hat Display Name",
    placeholder: "PlantedHat001"
  },
  {
    key: "mount_points_m",
    required: true,
    displayText: "Module Mounting Points (m)",
    unit: 17 /* METER_XYZ_4 */,
    tooltip: "The locations of the bottom of the mounting holes on modules relative to the top of the post (with the post top's center represented as 0,0,0), in meters. Assume that the post attached to the hat is oriented along a vector straight down (0, 0, -1)",
    default: [
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0]
    ]
  },
  {
    key: "mount_up_vectors",
    required: true,
    displayText: "Mount Up Vectors",
    unit: 15 /* VEC3_4 */,
    tooltip: "Four vectors equal to the normal vectors that extend upward from the planes of connected modules. Vectors are relative to an vector representing a post pointed straight into the ground (0, 0, -1)",
    default: [
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0]
    ]
  },
  {
    key: "module_tilt_deg",
    required: true,
    displayText: "Module Tilt",
    unit: 12 /* DEGREES */,
    tooltip: "Intended amount of module tilt for this hat",
    placeholder: 10,
    max: 89,
    min: 0
  },
  {
    key: "post_tilt_deg_about_width_dir",
    required: true,
    displayText: "Post Tilt",
    unit: 12 /* DEGREES */,
    tooltip: "Intended post tilt for this hat (usually 0 for Alfalfa)",
    placeholder: 0,
    max: 89,
    min: 0
  }
];
var getPartFieldsForPartType = (partType) => {
  switch (partType) {
    case "MODULE" /* MODULE */:
      return MODULE_PART_FIELDS;
    case "INVERTER" /* INVERTER */:
      return INVERTER_PART_FIELDS;
    case "POST" /* POST */:
      return POST_PART_FIELDS;
    case "ALFALFA_HAT" /* ALFALFA_HAT */:
      return ALFALFA_HAT_PART_FIELDS;
    case "BERMUDA_HAT" /* BERMUDA_HAT */:
      return BERMUDA_HAT_PART_FIELDS;
    default:
      break;
  }
  throw Error("No matching part fields found");
};
var ALLOWABLE_POWER_W_ERROR = 1;
var ALLOWABLE_CELLS_IN_SERIES_ERROR = 0.025;
var ADDITIONAL_VALIDATIONS = {
  ["POST" /* POST */]: [],
  ["INVERTER" /* INVERTER */]: [],
  ["BERMUDA_HAT" /* BERMUDA_HAT */]: [],
  ["ALFALFA_HAT" /* ALFALFA_HAT */]: [],
  ["MODULE" /* MODULE */]: [
    {
      key: "power_equals_current_times_voltage",
      errorText: "Inputted power doesn't equal i_mp * v_mp, check datasheet or contact PPS team",
      validationFxn: (value) => {
        if (isNaN(value.i_mp) || value.i_mp === null || isNaN(value.v_mp) || value.v_mp === null) {
          return true;
        }
        const calculatedPower = value.v_mp * value.i_mp;
        return Math.abs(calculatedPower - value.power_w) <= ALLOWABLE_POWER_W_ERROR;
      }
    },
    {
      key: "cells_in_series_v_oc_mismatch",
      errorText: "Cells in series count indicates a different V_oc than entered. Check V_oc and cells in series count (should be ~60-72, or ~( V_oc / 0.69V ), usually 1/2 the total cell count).",
      validationFxn: (value, ctx) => {
        if (isNaN(value.v_oc) || value.v_oc === null || isNaN(value.cells_in_series) || value.cells_in_series === null) {
          return true;
        }
        if (value.cells_in_series === 0) {
          return true;
        }
        const cellVoc = value.v_oc / value.cells_in_series;
        const passing = cellVoc >= 0.69 - ALLOWABLE_CELLS_IN_SERIES_ERROR;
        const suggestedRange = [
          Math.ceil(value.v_oc / (0.69 + ALLOWABLE_CELLS_IN_SERIES_ERROR)),
          Math.floor(value.v_oc / (0.69 - ALLOWABLE_CELLS_IN_SERIES_ERROR))
        ];
        if (!passing) {
          return ctx.createError({
            message: `(V_oc / cells in series) = ${cellVoc.toFixed(3)}, which is significantly lower than the expected value of 0.69. 

Check V_oc and cells in series count (should be ~${suggestedRange[0]}-${suggestedRange[1]}, or ~( V_oc / 0.69V ), usually 1/2 the total cell count).`
          });
        } else
          return true;
      }
    }
  ]
};
var getYupSchemaFromPartFields = (partFields, partType) => {
  let baseSchema = object({
    is_partial: boolean().default(false),
    ...partFields.reduce((schema, field) => {
      let fieldSchema;
      switch (field.unit) {
        case 8 /* AMPS */:
        case 12 /* DEGREES */:
        case 3 /* INTEGER */:
        case 9 /* KILOVOLT_AMPS */:
        case 4 /* METERS */:
        case 10 /* PERCENTAGE */:
        case 11 /* PERCENTAGE_PER_DEGREE_CELSIUS */:
        case 13 /* DEGREES_CELSIUS */:
        case 7 /* VOLTS */:
        case 18 /* WATTS */:
          fieldSchema = number().when("is_partial", {
            is: true,
            then: (schema2) => field.required ? schema2 : schema2.notRequired().nullable(),
            otherwise: (schema2) => schema2
          });
          if (field.min !== void 0) {
            fieldSchema = fieldSchema.min(field.min);
          }
          if (field.max !== void 0) {
            fieldSchema = fieldSchema.max(field.max);
          }
          break;
        case 2 /* BOOLEAN */:
          fieldSchema = boolean();
          break;
        case 0 /* STRING */:
          fieldSchema = string();
          if (field.min !== void 0) {
            fieldSchema = fieldSchema.min(field.min);
          }
          if (field.max !== void 0) {
            fieldSchema = fieldSchema.max(field.max);
          }
          break;
        case 1 /* URL */:
          fieldSchema = string().url();
          if (field.min !== void 0) {
            fieldSchema = fieldSchema.min(field.min);
          }
          if (field.max !== void 0) {
            fieldSchema = fieldSchema.max(field.max);
          }
          break;
        case 16 /* METER_XYZ_2 */:
        case 14 /* VEC3_2 */:
          fieldSchema = array().of(array().of(number().required()).length(3)).length(2);
          break;
        case 17 /* METER_XYZ_4 */:
        case 15 /* VEC3_4 */:
          fieldSchema = array().of(array().of(number().required()).length(3)).length(4);
          break;
        case 19 /* CELL_TECH */:
          fieldSchema = string().when("is_partial", {
            is: false,
            then: (schema2) => schema2.oneOf(
              CELL_TECH_OPTIONS.map(
                (option) => option.value
              ),
              (ctx) => `Cell tech must be one of the following values: ${ctx?.values}`
            ),
            otherwise: (schema2) => schema2.nullable()
          });
          break;
        default:
          break;
      }
      if (fieldSchema && field.required) {
        fieldSchema = fieldSchema.required();
      }
      return {
        ...schema,
        [field.key]: fieldSchema
      };
    }, {})
  });
  if (ADDITIONAL_VALIDATIONS[partType].length > 0) {
    ADDITIONAL_VALIDATIONS[partType].forEach((validation) => {
      baseSchema = baseSchema.test(
        validation.key,
        validation.errorText,
        validation.validationFxn
      );
    });
  }
  if (partType === "MODULE" /* MODULE */) {
    baseSchema = baseSchema.transform((value) => {
      let isIncomplete = false;
      MODULE_PART_FIELDS.filter((field) => !field.required).forEach((field) => {
        if (value[field.key] !== null && value[field.key] !== 0) {
          isIncomplete = true;
        }
      });
      let newValue = structuredClone(value);
      newValue.is_partial = !isIncomplete;
      return newValue;
    });
  }
  return baseSchema;
};
var replacePartKeyWithDisplayText = (partFields, key, text) => {
  const trimmedKey = key.split("[")[0];
  const partField = partFields.find((field) => field.key === trimmedKey);
  if (!partField)
    return text;
  return text.replace(trimmedKey, `${partField.displayText} `);
};
var getPartValidationError = async (part, partType) => {
  const partFields = getPartFieldsForPartType(partType);
  const validationSchema = getYupSchemaFromPartFields(partFields, partType);
  try {
    const res = await validationSchema.validate(part);
    if (partType === "MODULE" /* MODULE */ && part.is_partial !== void 0 && res.is_partial !== part.is_partial) {
      throw Error("Part's is_partial value is set incorrectly");
    }
    return null;
  } catch (error) {
    if (error?.params?.path) {
      return {
        key: error.params.path,
        message: replacePartKeyWithDisplayText(
          partFields,
          error.params.path,
          error.message
        )
      };
    }
    return {
      key: "",
      message: error.message
    };
  }
};

// src/equal.ts
var CLOSE_ENOUGH = 1e-9;
var areAllDecimalsEqual = (numbers) => {
  for (const number2 of numbers) {
    if (!areDecimalsEqual(number2, numbers[0])) {
      return false;
    }
  }
  return true;
};
var areDecimalsEqual = (number1, number2) => {
  return Math.abs(number1 - number2) < CLOSE_ENOUGH;
};
var areVectorsEqual = (vector1, vector2) => {
  return vector1.x === vector2.x && vector1.y === vector2.y && vector1.z === vector2.z;
};

// src/types.ts
var ArrayDesignVersion = /* @__PURE__ */ ((ArrayDesignVersion2) => {
  ArrayDesignVersion2["ALFALFA"] = "ALFALFA";
  ArrayDesignVersion2["BERMUDA"] = "BERMUDA";
  return ArrayDesignVersion2;
})(ArrayDesignVersion || {});
var AnchorPointKey = /* @__PURE__ */ ((AnchorPointKey2) => {
  AnchorPointKey2["BOTTOM_LEFT"] = "BOTTOM_LEFT";
  AnchorPointKey2["TOP_LEFT"] = "TOP_LEFT";
  AnchorPointKey2["TOP_RIGHT"] = "TOP_RIGHT";
  AnchorPointKey2["BOTTOM_RIGHT"] = "BOTTOM_RIGHT";
  return AnchorPointKey2;
})(AnchorPointKey || {});

// src/transformParts/transformHatParts.ts
var transformHat = (rawHat) => {
  switch (rawHat.type) {
    case "ALFALFA" /* ALFALFA */:
      return transformAlfalfaHat(rawHat);
    case "BERMUDA" /* BERMUDA */:
      return transformBermudaHat(rawHat);
  }
  console.error(`Invalid hat part property name: ${rawHat.part_property_name}`);
  return null;
};
var transformAlfalfaHat = (rawHat) => {
  const upVectors = rawHat.mount_up_vectors.map((xyz) => {
    return { x: xyz[0], y: xyz[1], z: xyz[2] };
  });
  const mountPoints = rawHat.mount_points_m.map((xyz) => {
    return { x: xyz[0], y: xyz[1], z: xyz[2] };
  });
  const postUpVector = { x: 0, y: 0, z: 1 };
  const postPoint = { x: 0, y: 0, z: 0 };
  const getXDistanceToPost = (point) => Math.abs(point.x - postPoint.x);
  const getYDistanceToPost = (point) => Math.abs(point.y - postPoint.y);
  const getZDistanceToPost = (point) => point.z - postPoint.z;
  const postToModuleLengths = mountPoints.map(getXDistanceToPost);
  const postToModuleWidths = mountPoints.map(getYDistanceToPost);
  const postToModuleHeights = mountPoints.map(getZDistanceToPost);
  const getTiltDegToPostAboutLengthDir = (upVector) => {
    return getTiltDegAboutXAxis(upVector, postUpVector);
  };
  const getTiltDegToPostAboutWidthDir = (upVector) => {
    return getTiltDegAboutYAxis(upVector, postUpVector);
  };
  const tiltsDegAboutWidthDir = upVectors.map(getTiltDegToPostAboutWidthDir);
  const tiltsDegAboutLengthDir = upVectors.map(getTiltDegToPostAboutLengthDir);
  const [
    tiltDegAboutLengthDir1,
    tiltDegAboutLengthDir2,
    tiltDegAboutLengthDir3,
    tiltDegAboutLengthDir4
  ] = tiltsDegAboutLengthDir;
  if (!areAllDecimalsEqual(postToModuleLengths)) {
    logAlfalfaError(rawHat.id, "all postToModuleLengths must equal");
    return null;
  }
  if (!areAllDecimalsEqual(postToModuleWidths)) {
    logAlfalfaError(rawHat.id, "all postToModuleWidths must equal");
    return null;
  }
  if (!areAllDecimalsEqual(postToModuleHeights)) {
    logAlfalfaError(rawHat.id, "all postToModuleHeights must equal");
    return null;
  }
  if (!areAllDecimalsEqual(tiltsDegAboutWidthDir)) {
    logAlfalfaError(rawHat.id, "all tiltsDegAboutWidthDir must equal");
    return null;
  }
  if (!areAllDecimalsEqual([tiltDegAboutLengthDir1, tiltDegAboutLengthDir4])) {
    logAlfalfaError(
      rawHat.id,
      "tiltDegAboutLengthDir must equal for up-vectors 1 and 4"
    );
    return null;
  }
  if (!areAllDecimalsEqual([tiltDegAboutLengthDir2, tiltDegAboutLengthDir3])) {
    logAlfalfaError(
      rawHat.id,
      "tiltDegAboutLengthDir must equal for up-vectors 2 and 3"
    );
    return null;
  }
  if (!areAllDecimalsEqual([
    0,
    tiltDegAboutLengthDir1 + tiltDegAboutLengthDir2,
    tiltDegAboutLengthDir3 + tiltDegAboutLengthDir4
  ])) {
    logAlfalfaError(
      rawHat.id,
      "tiltDegAboutLengthDir must sum to 0\xB0 for up-vectors (1+2) and also for (3+4)"
    );
    return null;
  }
  const postToModuleLength = postToModuleLengths[0];
  const postToModuleWidth = postToModuleWidths[0];
  const postToModuleHeight = postToModuleHeights[0];
  const moduleTiltDeg = tiltsDegAboutWidthDir[0];
  const postTiltDegAboutLengthDir = Math.min(
    tiltDegAboutLengthDir1,
    tiltDegAboutLengthDir2
  );
  return {
    id: rawHat.id,
    name: rawHat.name,
    type: rawHat.part_type,
    arrayDesignVersion: "ALFALFA" /* ALFALFA */,
    postToModuleLength,
    postToModuleWidth,
    postToModuleHeight,
    moduleTiltDeg,
    postTiltDegAboutLengthDir,
    postTiltDegAboutWidthDir: 0,
    mountPoints: rawHat.mount_points_m,
    mountUpVectors: rawHat.mount_up_vectors,
    rotationDegAboutCenterline: 0,
    defaultKeys: rawHat.default,
    isDeprecated: rawHat.is_deprecated,
    updatedPartId: rawHat.updated_part_id,
    createdAt: rawHat.created_at
  };
};
var transformBermudaHat = (rawHat) => {
  const orientedBermudaHatData = orientBermudaHat(rawHat);
  if (orientedBermudaHatData === null) {
    return null;
  }
  const { mountPoints, upVectors, hatRotationZDeg } = orientedBermudaHatData;
  const postToModuleLengths = mountPoints.map((mountPoint) => mountPoint.x);
  const postToModuleWidths = mountPoints.map((mountPoint) => Math.abs(mountPoint.y));
  const postToModuleHeights = mountPoints.map((mountPoint) => mountPoint.z);
  if (!areAllDecimalsEqual(postToModuleLengths)) {
    logBermudaError(rawHat.id, "all postToModuleLengths must equal");
    return null;
  }
  if (!areAllDecimalsEqual(postToModuleWidths)) {
    logBermudaError(rawHat.id, "all postToModuleWidths must equal");
    return null;
  }
  if (!areAllDecimalsEqual(postToModuleHeights)) {
    logBermudaError(rawHat.id, "all postToModuleHeights must equal");
    return null;
  }
  if (upVectors[0].x === 0 && upVectors[0].y === 0 && upVectors[0].z === 1 && postToModuleLengths[0] !== 0) {
    logBermudaError(
      rawHat.id,
      "hat can still connect to module after a 180\xB0 rotation, however it would connect in a different location (non-zero postToModuleLength)"
    );
    return null;
  }
  return {
    id: rawHat.id,
    name: rawHat.name,
    type: rawHat.part_type,
    arrayDesignVersion: "BERMUDA" /* BERMUDA */,
    postToModuleLength: postToModuleLengths[0],
    postToModuleWidth: postToModuleWidths[0],
    postToModuleHeight: postToModuleHeights[0],
    moduleTiltDeg: rawHat.module_tilt_deg,
    postTiltDegAboutLengthDir: 0,
    postTiltDegAboutWidthDir: rawHat.post_tilt_deg_about_width_dir,
    mountPoints: rawHat.mount_points_m,
    mountUpVectors: rawHat.mount_up_vectors,
    rotationDegAboutCenterline: hatRotationZDeg,
    defaultKeys: rawHat.default,
    isDeprecated: rawHat.is_deprecated,
    updatedPartId: rawHat.updated_part_id,
    createdAt: rawHat.created_at
  };
};
var orientBermudaHat = (rawHat) => {
  let postUpVector = { x: 0, y: 0, z: 1 };
  let mountPoints = rawHat.mount_points_m.map((xyz) => {
    return { x: xyz[0], y: xyz[1], z: xyz[2] };
  });
  let upVectors = rawHat.mount_up_vectors.map((xyz) => {
    return { x: xyz[0], y: xyz[1], z: xyz[2] };
  });
  const moduleTiltDeg = rawHat.module_tilt_deg;
  const postTiltDegAboutWidthDir = rawHat.post_tilt_deg_about_width_dir;
  if (mountPoints.length !== 2) {
    logBermudaError(rawHat.id, "must have exactly 2 mount points");
    return null;
  }
  if (upVectors.length !== 2) {
    logBermudaError(rawHat.id, "must have exactly 2 up vectors");
    return null;
  }
  if (mountPoints[0].z !== mountPoints[1].z) {
    logBermudaError(rawHat.id, "mount points' z-values must equal");
    return null;
  }
  if (!areVectorsEqual(upVectors[0], upVectors[1])) {
    logBermudaError(rawHat.id, "up-vectors must be equal");
    return null;
  }
  let hatRotationZDeg = -radToDeg(Math.atan2(upVectors[0].y, upVectors[0].x));
  mountPoints = mountPoints.map((point) => rotateAboutZAxis(point, hatRotationZDeg));
  upVectors = upVectors.map((vector) => rotateAboutZAxis(vector, hatRotationZDeg));
  let hatRotationYDeg = getHatYRotationDegToModule(upVectors[0], moduleTiltDeg);
  const isFacingWrongWay = !areDecimalsEqual(
    Math.abs(hatRotationYDeg),
    postTiltDegAboutWidthDir
  );
  if (isFacingWrongWay) {
    hatRotationZDeg += 180;
    mountPoints = mountPoints.map((point) => rotateAboutZAxis(point, 180));
    upVectors = upVectors.map((vector) => rotateAboutZAxis(vector, 180));
    hatRotationYDeg = getHatYRotationDegToModule(upVectors[0], moduleTiltDeg);
    const isValid = areDecimalsEqual(
      Math.abs(hatRotationYDeg),
      postTiltDegAboutWidthDir
    );
    if (!isValid) {
      logBermudaError(
        rawHat.id,
        "hat part's up-vectors are incompatible with the hat's intended module tilt and post tilt"
      );
      return null;
    }
  }
  mountPoints = mountPoints.map((point) => rotateAboutYAxis(point, hatRotationYDeg));
  upVectors = upVectors.map((vector) => rotateAboutYAxis(vector, hatRotationYDeg));
  postUpVector = rotateAboutYAxis(postUpVector, hatRotationYDeg);
  return {
    mountPoints,
    upVectors,
    postUpVector,
    hatRotationZDeg
  };
};
var getHatYRotationDegToModule = (upVector, moduleTiltDeg) => {
  const upVectorAngleDeg = radToDeg(Math.atan2(upVector.z, upVector.x));
  return -90 + moduleTiltDeg + upVectorAngleDeg;
};
var logAlfalfaError = (id, message) => {
  logHatError(id, message, "alfalfa");
};
var logBermudaError = (id, message) => {
  logHatError(id, message, "bermuda");
};
var logHatError = (id, message, hatType) => {
  console.error(`Invalid ${hatType} hat with ID ${id}: ${message}`);
};
var getTiltDegAboutXAxis = (direction1, direction2) => {
  const angleRad1 = Math.atan2(direction1.y, direction1.z);
  const angleRad2 = Math.atan2(direction2.y, direction2.z);
  const dAngleRad = Math.abs(angleRad2 - angleRad1);
  return radToDeg(dAngleRad);
};
var getTiltDegAboutYAxis = (direction1, direction2) => {
  const angleRad1 = Math.atan2(direction1.z, direction1.x);
  const angleRad2 = Math.atan2(direction2.z, direction2.x);
  const dAngleRad = Math.abs(angleRad2 - angleRad1);
  return radToDeg(dAngleRad);
};
var rotateAboutZAxis = (vector, angleDeg) => {
  const angleRad = degToRad(angleDeg);
  const cosAngle = Math.cos(angleRad);
  const sinAngle = Math.sin(angleRad);
  return {
    x: vector.x * cosAngle - vector.y * sinAngle,
    y: vector.x * sinAngle + vector.y * cosAngle,
    z: vector.z
  };
};
var rotateAboutYAxis = (vector, angleDeg) => {
  const angleRad = degToRad(angleDeg);
  const cosAngle = Math.cos(angleRad);
  const sinAngle = Math.sin(angleRad);
  return {
    x: vector.x * cosAngle + vector.z * sinAngle,
    y: vector.y,
    z: -(vector.x * sinAngle) + vector.z * cosAngle
  };
};
var degToRad = (rad) => rad * Math.PI / 180;
var radToDeg = (deg) => deg / Math.PI * 180;

// src/transformParts/transformInverterParts.ts
var DEFAULT_INVERTER_WIDTH_M = 6.058;
var DEFAULT_INVERTER_LENGTH_M = 6.058;
var DEFAULT_INVERTER_HEIGHT_M = 2.896;
var transformInverter = (rawInverter) => {
  return {
    id: rawInverter.id,
    name: `${rawInverter.model_name} - ${rawInverter.manufacturer_name}`,
    type: rawInverter.part_type,
    width: rawInverter.width_m ?? DEFAULT_INVERTER_WIDTH_M,
    length: rawInverter.length_m ?? DEFAULT_INVERTER_LENGTH_M,
    height: rawInverter.height_m ?? DEFAULT_INVERTER_HEIGHT_M,
    acOutputKW: rawInverter.max_output_ac_kva,
    defaultKeys: rawInverter.default,
    isDeprecated: rawInverter.is_deprecated,
    updatedPartId: rawInverter.updated_part_id,
    createdAt: rawInverter.created_at,
    isPartial: rawInverter.is_partial
  };
};

// src/transformParts/transformModuleParts.ts
var transformModule = (rawModule) => {
  const isPartial = rawModule.is_partial;
  const vOcVolts = rawModule.v_oc;
  const vMpVolts = rawModule.v_mp;
  const iMpAmps = rawModule.i_mp;
  const tempCoefficientIsc = rawModule.temp_coefficient_i_sc;
  const tempCoefficientVoc = rawModule.temp_coefficient_v_oc;
  const dcOutputKW = (isPartial ? rawModule.power_w : vMpVolts * iMpAmps) / 1e3;
  return {
    id: rawModule.id,
    name: `${rawModule.manufacturer_name} ${rawModule.product_name} [${rawModule.power_w}]`,
    type: rawModule.part_type,
    width: Math.abs(rawModule.width_m),
    length: Math.abs(rawModule.length_m),
    height: isPartial ? 0.035 : Math.abs(rawModule.thickness_m),
    dcOutputKW,
    vOcVolts,
    vMpVolts,
    iMpAmps,
    edgeToMountLength: isPartial ? Math.abs(rawModule.length_m) / 4 : rawModule.edge2mount_x_m,
    edgeToMountWidth: isPartial ? 0.02 : rawModule.edge2mount_y_m,
    slotLength: isPartial ? 0 : rawModule.slot_length_m,
    defaultKeys: rawModule.default,
    isPartial,
    isDeprecated: rawModule.is_deprecated,
    updatedPartId: rawModule.updated_part_id,
    createdAt: rawModule.created_at,
    tempCoefficientIsc,
    tempCoefficientVoc,
    cellsInSeries: rawModule.cells_in_series
  };
};

// src/transformParts/transformPostParts.ts
var transformPost = (rawPost) => {
  return {
    id: rawPost.id,
    name: rawPost.name,
    type: rawPost.part_type,
    width: rawPost.diameter_m,
    length: rawPost.diameter_m,
    height: rawPost.length_m,
    defaultKeys: rawPost.default,
    isDeprecated: rawPost.is_deprecated,
    updatedPartId: rawPost.updated_part_id,
    createdAt: rawPost.created_at
  };
};

// src/transformParts/transformParts.ts
var transformParts = (parts) => {
  return parts.map(transformPart).filter((part) => part !== null).map((part) => part);
};
var transformPart = (part) => {
  switch (part.part_type) {
    case "MODULE" /* MODULE */:
      return transformModule(part);
    case "POST" /* POST */:
      return transformPost(part);
    case "INVERTER" /* INVERTER */:
      return transformInverter(part);
    case "BERMUDA_HAT" /* BERMUDA_HAT */:
    case "ALFALFA_HAT" /* ALFALFA_HAT */:
      return transformHat(part);
  }
  console.warn(`Could not transform part "${part.name}" of type "${part.part_type}"`);
  return null;
};

// src/enuConversion.ts
var DEG2RAD = Math.PI / 180;
var RAD2DEG = 180 / Math.PI;
var WGS84A = 6378137;
var WGS84B = 635675231424518e-8;
function geodeticToEnu(lng, lat, alt, refLng, refLat, refAlt) {
  const ecef = geodeticToEcef(lng, lat, alt);
  return ecefToEnu(ecef[0], ecef[1], ecef[2], refLng, refLat, refAlt);
}
function enuToGeodetic(x, y, z, refLng, refLat, refAlt) {
  const ecef = enuToEcef(x, y, z, refLng, refLat, refAlt);
  return ecefToGeodetic(ecef[0], ecef[1], ecef[2]);
}
function ecefToEnu(X, Y, Z, refLng, refLat, refAlt) {
  const refEcef = geodeticToEcef(refLng, refLat, refAlt);
  const V = [X - refEcef[0], Y - refEcef[1], Z - refEcef[2]];
  refLng = refLng * DEG2RAD;
  refLat = refLat * DEG2RAD;
  const cosLng = Math.cos(refLng);
  const sinLng = Math.sin(refLng);
  const cosLat = Math.cos(refLat);
  const sinLat = Math.sin(refLat);
  const x = -sinLng * V[0] + cosLng * V[1];
  const y = -sinLat * cosLng * V[0] - sinLat * sinLng * V[1] + cosLat * V[2];
  const z = cosLat * cosLng * V[0] + cosLat * sinLng * V[1] + sinLat * V[2];
  return [x, y, z];
}
function enuToEcef(x, y, z, refLng, refLat, refAlt) {
  const refEcef = geodeticToEcef(refLng, refLat, refAlt);
  refLng = refLng * DEG2RAD;
  refLat = refLat * DEG2RAD;
  const cosLng = Math.cos(refLng);
  const sinLng = Math.sin(refLng);
  const cosLat = Math.cos(refLat);
  const sinLat = Math.sin(refLat);
  const X = -sinLng * x - sinLat * cosLng * y + cosLat * cosLng * z + refEcef[0];
  const Y = cosLng * x - sinLat * sinLng * y + cosLat * sinLng * z + refEcef[1];
  const Z = cosLat * y + sinLat * z + refEcef[2];
  return [X, Y, Z];
}
function geodeticToEcef(lng, lat, alt) {
  const a = WGS84A;
  const b = WGS84B;
  lng = lng * DEG2RAD;
  lat = lat * DEG2RAD;
  const cosLng = Math.cos(lng);
  const sinLng = Math.sin(lng);
  const cosLat = Math.cos(lat);
  const sinLat = Math.sin(lat);
  const a2 = a * a;
  const b2 = b * b;
  const L = 1 / Math.sqrt(a2 * cosLat * cosLat + b2 * sinLat * sinLat);
  const nhcl = (a2 * L + alt) * cosLat;
  const X = nhcl * cosLng;
  const Y = nhcl * sinLng;
  const Z = (b2 * L + alt) * sinLat;
  return [X, Y, Z];
}
function ecefToGeodetic(X, Y, Z) {
  const a = WGS84A;
  const b = WGS84B;
  const a2 = a * a;
  const b2 = b * b;
  const a2mb2 = a2 - b2;
  const ea = Math.sqrt(a2mb2 / a2);
  const eb = Math.sqrt(a2mb2 / b2);
  const p = Math.sqrt(X * X + Y * Y);
  const theta = Math.atan2(Z * a, p * b);
  const sinTheta = Math.sin(theta);
  const cosTheta = Math.cos(theta);
  const lng = Math.atan2(Y, X);
  const lat = Math.atan2(
    Z + eb * eb * b * sinTheta * sinTheta * sinTheta,
    p - ea * ea * a * cosTheta * cosTheta * cosTheta
  );
  const sinLat = Math.sin(lat);
  const cosLat = Math.cos(lat);
  const N = a / Math.sqrt(1 - ea * ea * sinLat * sinLat);
  const alt = p / cosLat - N;
  return [lng * RAD2DEG, lat * RAD2DEG, alt];
}

// src/geometry.ts
import { Matrix4, Quaternion, Vector3 } from "three";
var getRectangularPrismCorners = (lat, lon, alt, quatW, quatX, quatY, quatZ, width, length, height, heightMin, heightMax) => {
  const quaternion = new Quaternion(quatX, quatY, quatZ, quatW);
  const widthDefault = new Vector3(0, 1, 0);
  const lengthDefault = new Vector3(1, 0, 0);
  const heightDefault = new Vector3(0, 0, 1);
  widthDefault.applyQuaternion(quaternion);
  lengthDefault.applyQuaternion(quaternion);
  heightDefault.applyQuaternion(quaternion);
  const [widthX, widthY, widthZ] = widthDefault.toArray();
  const [lengthX, lengthY, lengthZ] = lengthDefault.toArray();
  const [heightX, heightY, heightZ] = heightDefault.toArray();
  const cornerMultipliers = [
    [-0.5, -0.5, heightMin],
    [-0.5, 0.5, heightMin],
    [0.5, 0.5, heightMin],
    [0.5, -0.5, heightMin],
    [-0.5, -0.5, heightMax],
    [-0.5, 0.5, heightMax],
    [0.5, 0.5, heightMax],
    [0.5, -0.5, heightMax]
  ];
  const cornerXYZs = cornerMultipliers.map((cornerMultipliers2) => {
    const [widthMultiplier, lengthMultiplier, heightMultiplier] = cornerMultipliers2;
    const x = widthMultiplier * widthX * width + lengthMultiplier * lengthX * length + heightMultiplier * heightX * height;
    const y = widthMultiplier * widthY * width + lengthMultiplier * lengthY * length + heightMultiplier * heightY * height;
    const z = widthMultiplier * widthZ * width + lengthMultiplier * lengthZ * length + heightMultiplier * heightZ * height;
    return { x, y, z };
  });
  return cornerXYZs.map(({ x, y, z }) => enuToGeodetic(x, y, z, lon, lat, alt));
};
var updateQuaternionEnuOrigin = (quaternion, oldEnuOrigin, newEnuOrigin) => {
  const oldEnuVectors = [
    [0, 0, 0],
    [1, 0, 0],
    [0, 1, 0],
    [0, 0, 1]
  ];
  const newEnuVectors = [];
  for (let i = 0; i < oldEnuVectors.length; i++) {
    const [oldX, oldY, oldZ] = oldEnuVectors[i];
    const [lon, lat, alt] = enuToGeodetic(
      oldX,
      oldY,
      oldZ,
      oldEnuOrigin.lon,
      oldEnuOrigin.lat,
      oldEnuOrigin.alt
    );
    const [newX, newY, newZ] = geodeticToEnu(
      lon,
      lat,
      alt,
      newEnuOrigin.lon,
      newEnuOrigin.lat,
      newEnuOrigin.alt
    );
    newEnuVectors.push([newX, newY, newZ]);
  }
  for (let i = 1; i <= 3; i++) {
    newEnuVectors[i][0] -= newEnuVectors[0][0];
    newEnuVectors[i][1] -= newEnuVectors[0][1];
    newEnuVectors[i][2] -= newEnuVectors[0][2];
  }
  const coord1 = newEnuVectors[1];
  const coord2 = newEnuVectors[2];
  const coord3 = newEnuVectors[3];
  const vector1 = new Vector3(coord1[0], coord1[1], coord1[2]);
  const vector2 = new Vector3(coord2[0], coord2[1], coord2[2]);
  const vector3 = new Vector3(coord3[0], coord3[1], coord3[2]);
  const rotationMatrix = new Matrix4().makeBasis(vector1, vector2, vector3);
  const rotationQuaternion = new Quaternion().setFromRotationMatrix(rotationMatrix);
  const outputQuaternion = new Quaternion(
    quaternion.x,
    quaternion.y,
    quaternion.z,
    quaternion.w
  );
  outputQuaternion.multiply(rotationQuaternion);
  return outputQuaternion;
};

// src/tiltUtils.ts
import { MathUtils } from "three";
var RAD2DEG2 = 180 / Math.PI;
var ArrayTypes = /* @__PURE__ */ ((ArrayTypes2) => {
  ArrayTypes2["Alfalfa"] = "alfalfa";
  ArrayTypes2["Bermuda"] = "bermuda";
  return ArrayTypes2;
})(ArrayTypes || {});
var mountUpVectorsFromTilts = (postTilt, moduleTilt, arrayType, lowHat = true) => {
  const postToModule = MathUtils.DEG2RAD * (lowHat || arrayType === "alfalfa" ? 90 + moduleTilt + postTilt : 90 + moduleTilt - postTilt);
  let vectors = [
    {
      x: Math.cos(postToModule),
      y: 0,
      z: Math.sin(postToModule)
    },
    {
      x: Math.cos(postToModule),
      y: 0,
      z: Math.sin(postToModule)
    }
  ];
  if (arrayType === "alfalfa") {
    const additionalVectors = [
      {
        x: -Math.cos(postToModule),
        y: 0,
        z: Math.sin(postToModule)
      },
      {
        x: -Math.cos(postToModule),
        y: 0,
        z: Math.sin(postToModule)
      }
    ];
    vectors = lowHat ? [...vectors, ...additionalVectors] : [...additionalVectors, ...vectors];
  }
  return vectors;
};

// src/coordinateReferenceSystems.ts
var SUPPORTED_CRS_TYPES = /* @__PURE__ */ ((SUPPORTED_CRS_TYPES2) => {
  SUPPORTED_CRS_TYPES2[SUPPORTED_CRS_TYPES2["WGS84"] = 0] = "WGS84";
  SUPPORTED_CRS_TYPES2[SUPPORTED_CRS_TYPES2["IllinoisStatePlane"] = 1] = "IllinoisStatePlane";
  SUPPORTED_CRS_TYPES2[SUPPORTED_CRS_TYPES2["NAD83UTM10N"] = 2] = "NAD83UTM10N";
  return SUPPORTED_CRS_TYPES2;
})(SUPPORTED_CRS_TYPES || {});
var SUPPORTED_CRS_DATA = {
  [0 /* WGS84 */]: {
    name: "WGS84",
    proj4: "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs",
    epsg: 4326
  },
  [1 /* IllinoisStatePlane */]: {
    name: "IL83-EF (NAD83 / Illinois East (ftUS))",
    proj4: "+proj=tmerc +lat_0=36.6666666666667 +lon_0=-88.3333333333333 +k=0.999975 +x_0=300000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs",
    epsg: 3435
  },
  [2 /* NAD83UTM10N */]: {
    name: "NAD83 / UTM10N",
    proj4: "+proj=utm +zone=10 +ellps=GRS80 +datum=NAD83 +units=m +no_defs",
    epsg: 3157
  }
};
var getUTMCRSData = () => {
  let allUTMs = [];
  for (let i = 0; i < 60; i++) {
    allUTMs.push({
      name: `UTM Zone ${1 + i}N`,
      searchTerm: `${1 + i}N`,
      proj4: getUTMProj4(i + 1, true)
    });
    allUTMs.push({
      name: `UTM Zone ${1 + i}S`,
      searchTerm: `${1 + i}S`,
      proj4: getUTMProj4(i + 1, false)
    });
  }
  return allUTMs;
};
var getUTMProj4 = (zone, isNorth) => {
  return `+proj=utm +zone=${zone} +${isNorth ? "north" : "south"} +ellps=WGS84 +datum=WGS84 +units=m +no_defs`;
};

// src/geofeatures.ts
var GEOFEATURE_SHAPE_TYPES = {
  MULTI_POINT: "multi_point",
  POLYGON: "polygon"
};
var GEOFEATURE_POLYGON_TYPES = {
  ROAD: "ROAD",
  RESTRICTED_AREA: "RESTRICTED_AREA",
  MODULE_ZONE: "MODULE_ZONE"
};
var GEOFEATURE_DATA_SOURCES = {
  CSV_ROVER: "csv_rover",
  CSV_LONLAT: "csv_lonlat",
  KML: "kml",
  MAP_DRAWING: "map_drawing",
  LAYOUT_CALCULATOR: "layout_calculator",
  TURNING_RADIUS_TOOL: "turning_radius_tool",
  POLYGON_OFFSET_TOOL: "polygon_offset_tool"
};

// src/slopeAnalysis.ts
import Color from "colorjs.io";
import * as turf from "@turf/turf";
var ACRES_PER_SQUARE_METER = 247105e-9;
var NUM_STEPS_PER_INTERPOLATED_COLOR = 12;
var SLOPE_ANALYSIS_COLORS = {
  green: [54, 163, 73],
  yellow: [254, 223, 154],
  red: [215, 25, 28]
};
var INFINITY_SPLIT_AMOUNT = 10;
var GREEN_YELLOW_RED_COLOR_STOPS = [
  {
    stopValue: 8,
    color: SLOPE_ANALYSIS_COLORS.green
  },
  {
    stopValue: 15,
    color: SLOPE_ANALYSIS_COLORS.yellow
  },
  {
    stopValue: Infinity,
    color: SLOPE_ANALYSIS_COLORS.red
  }
];
var readUploadedFileAsDataURL = (inputFile) => {
  const temporaryFileReader = new FileReader();
  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new Error("Problem parsing input file."));
    };
    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result);
    };
    temporaryFileReader.readAsDataURL(inputFile);
  });
};
var getColorForValue = (value, colorStops) => {
  if (value < colorStops[0].stopValue) {
    return colorStops[0].color;
  } else if (value > colorStops[colorStops.length - 1].stopValue) {
    return colorStops[colorStops.length - 1].color;
  } else {
    const colorStop = colorStops.find((stop, index) => {
      return stop.stopValue > value && colorStops[index - 1].stopValue <= value;
    });
    if (!colorStop) {
      return [0, 0, 0];
    }
    return colorStop.color;
  }
};
var findNearest = (value, nearest) => Math.round(value / nearest) * nearest;
var getColorForValueInterpolate = (value, valueMap, min, max, numSteps) => {
  const range = Math.abs(max - min);
  const valueStep = Math.round(range / numSteps * 1e3) / 1e3;
  const mapKey = Math.round((findNearest(value - min, valueStep) + min) * 1e3) / 1e3;
  if (mapKey > max) {
    const lastColorKey = Object.keys(valueMap).pop();
    if (lastColorKey) {
      return valueMap[lastColorKey];
    }
  }
  if (valueMap[mapKey]) {
    return valueMap[mapKey];
  } else {
    console.error(valueMap, mapKey, value, valueStep);
  }
};
var getValueColorMap = (min, max, colors, numSteps) => {
  const range = Math.abs(max - min);
  const stepsPerColorPair = Math.floor(numSteps / (colors.length - 1)) + 1;
  let colorSteps = [];
  for (let i = 0; i < colors.length - 1; i++) {
    const color1 = new Color(
      `rgb(${colors[i][0]},${colors[i][1]},${colors[i][2]})`
    );
    const color2 = new Color(
      `rgb(${colors[i + 1][0]},${colors[i + 1][1]},${colors[i + 1][2]})`
    );
    const values = Color.steps(color1, color2, {
      space: "lch",
      outputSpace: "srgb",
      steps: stepsPerColorPair
    });
    colorSteps.push(...values);
  }
  const valueStep = Math.round(range / numSteps * 1e3) / 1e3;
  let output = {};
  let colorIndex = 0;
  if (min === max) {
    return output;
  }
  for (let i = min; i <= max && colorIndex < colorSteps.length; i = Math.round((i + valueStep) * 1e3) / 1e3) {
    const { r, g, b } = new Color(colorSteps[colorIndex]).srgb;
    output[`${i}`] = [
      Math.floor(r * 255),
      Math.floor(g * 255),
      Math.floor(b * 255)
    ];
    colorIndex++;
  }
  return output;
};
var mixColors = (color1, color2 = [255, 255, 255]) => {
  const newColor = new Color(
    new Color(`rgb(${color1[0]}, ${color1[1]}, ${color1[2]})`).mix(
      new Color(`rgb(${color2[0]}, ${color2[1]}, ${color2[2]})`),
      0.5,
      {
        space: "hsv",
        outputSpace: "srgb"
      }
    )
  );
  const { r, g, b } = newColor.srgb;
  return [Math.floor(r * 255), Math.floor(g * 255), Math.floor(b * 255)];
};
var getRecoloredSlopeData = async (slopeImageData, slopeAnalysisOptions) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx?.drawImage(img, 0, 0);
      const imageData = ctx?.getImageData(0, 0, canvas.width, canvas.height);
      const slopeImageData2 = imageData?.data;
      if (!slopeImageData2) {
        reject(new Error("Couldn't get slope image data"));
        return;
      }
      const scalingFactor = (2 ** 24 - 1) / 90;
      const { colorStops, interpolateSlope } = slopeAnalysisOptions;
      let areaTotals = {};
      const slopeClippingPolygon = slopeAnalysisOptions.clippingPolygon !== null ? slopeAnalysisOptions.clippingPolygon : getBboxPolygon(slopeAnalysisOptions.bbox);
      if (interpolateSlope) {
        const minSlope = 0;
        const maxSlope = colorStops[colorStops.length - 2].stopValue;
        const valueColorMap = getValueColorMap(
          minSlope,
          maxSlope,
          colorStops.map((stop) => stop.color),
          NUM_STEPS_PER_INTERPOLATED_COLOR * colorStops.length
        );
        for (let i = 0; i < slopeImageData2.length; i += 4) {
          const r = slopeImageData2[i];
          const g = slopeImageData2[i + 1];
          const b = slopeImageData2[i + 2];
          const a = slopeImageData2[i + 3];
          const encodedValue = r << 16 | g << 8 | b;
          const slopeValue = encodedValue / scalingFactor;
          let color = [0, 0, 0];
          if (slopeValue <= minSlope || minSlope === maxSlope) {
            color = colorStops[0].color;
          } else if (slopeValue >= maxSlope) {
            color = colorStops[colorStops.length - 1].color;
          } else {
            const colorForValue = getColorForValueInterpolate(
              slopeValue,
              valueColorMap,
              minSlope,
              maxSlope,
              NUM_STEPS_PER_INTERPOLATED_COLOR * colorStops.length
            );
            color = [colorForValue[0], colorForValue[1], colorForValue[2]];
          }
          slopeImageData2[i] = color[0];
          slopeImageData2[i + 1] = color[1];
          slopeImageData2[i + 2] = color[2];
          const pixelCoords = getPixelXY(i, canvas.width);
          const opacity = a === 0 ? 0 : getPixelOpacity(
            pixelCoords[0],
            pixelCoords[1],
            canvas.width,
            canvas.height,
            slopeAnalysisOptions.bbox,
            slopeClippingPolygon
          );
          slopeImageData2[i + 3] = opacity;
        }
      } else {
        for (let i = 0; i < slopeImageData2.length; i += 4) {
          const r = slopeImageData2[i];
          const g = slopeImageData2[i + 1];
          const b = slopeImageData2[i + 2];
          const a = slopeImageData2[i + 3];
          const encodedValue = r << 16 | g << 8 | b;
          const slopeValue = encodedValue / scalingFactor;
          const colorForValue = getColorForValue(slopeValue, colorStops);
          slopeImageData2[i] = colorForValue[0];
          slopeImageData2[i + 1] = colorForValue[1];
          slopeImageData2[i + 2] = colorForValue[2];
          const pixelCoords = getPixelXY(i, canvas.width);
          const opacity = a === 0 ? 0 : getPixelOpacity(
            pixelCoords[0],
            pixelCoords[1],
            canvas.width,
            canvas.height,
            slopeAnalysisOptions.bbox,
            slopeClippingPolygon
          );
          slopeImageData2[i + 3] = opacity;
          if (opacity !== 0) {
            const colorKey = `rgb(${colorForValue[0]},${colorForValue[1]},${colorForValue[2]})`;
            if (areaTotals[colorKey] !== void 0) {
              areaTotals[colorKey] += 1;
            } else {
              areaTotals[colorKey] = 1;
            }
          }
        }
      }
      if (imageData) {
        ctx?.putImageData(imageData, 0, 0);
        const bboxPolygon = getBboxPolygon(slopeAnalysisOptions.bbox);
        const areaToAnalyzePolygon = slopeAnalysisOptions.clippingPolygon !== null ? turf.polygon([slopeAnalysisOptions.clippingPolygon]) : turf.polygon([bboxPolygon]);
        const fullAreaData = getAdditionalAreaData(
          areaTotals,
          areaToAnalyzePolygon
        );
        resolve({
          dataURL: canvas.toDataURL(),
          areaTotals: fullAreaData
        });
      } else {
        reject(new Error("No image data"));
      }
    };
    img.src = slopeImageData;
  });
};
var getAdditionalAreaData = (areaTotals, polygonArea) => {
  const totalPixelCount = Object.values(areaTotals).reduce(
    (totalPixelCount2, colorPixelCount) => colorPixelCount + totalPixelCount2,
    0
  );
  const totalAreaSquareMeters = turf.area(polygonArea);
  let output = {};
  Object.keys(areaTotals).forEach((key) => {
    const areaMeters = totalAreaSquareMeters * (areaTotals[key] / totalPixelCount);
    output[key] = {
      coveragePercent: areaTotals[key] / totalPixelCount,
      acres: areaMeters * ACRES_PER_SQUARE_METER,
      squareMeters: areaMeters
    };
  });
  return output;
};
var getPixelXY = (imageDataIndex, width) => {
  const y = Math.floor(imageDataIndex / 4 / width);
  const x = imageDataIndex / 4 % width;
  return [x, y];
};
var lonLatToPixel = (lon, lat, bbox, width, height) => {
  const x = (lon - bbox[0][0]) / (bbox[1][0] - bbox[0][0]) * width;
  const y = (lat - bbox[0][1]) / (bbox[2][1] - bbox[0][1]) * height;
  return [x, y];
};
var pointInPolygon = (point, polygon2) => {
  let inside = false;
  for (let i = 0, j = polygon2.length - 1; i < polygon2.length; j = i++) {
    const xi = polygon2[i][0], yi = polygon2[i][1];
    const xj = polygon2[j][0], yj = polygon2[j][1];
    const intersect = yi > point[1] != yj > point[1] && point[0] < (xj - xi) * (point[1] - yi) / (yj - yi) + xi;
    if (intersect)
      inside = !inside;
  }
  return inside;
};
var getPixelOpacity = (x, y, w, h, bbox, clippingPolygon) => {
  const pixelCoordPolygon = clippingPolygon.map((point) => {
    return lonLatToPixel(point[0], point[1], bbox, w, h);
  });
  return pointInPolygon([x, y], pixelCoordPolygon) ? 255 : 0;
};
var getBboxCoordinates = (dsm) => {
  const [lonA, latA, lonB, latB] = dsm.bbox;
  return [
    [lonA, latB],
    [lonB, latB],
    [lonB, latA],
    [lonA, latA]
  ];
};
var getBboxPolygon = (bbox) => {
  return bbox.concat([bbox[0]]);
};
var COLOR_RANGE_OPTIONS = {
  GREEN_TO_RED: new Color("rgb(0,255,0)").range("rgb(255,0,0)", {
    space: "hsl",
    hue: "decreasing",
    outputSpace: "srgb"
  }),
  GRAYSCALE: new Color("rgb(255,255,255)").range("rgb(0,0,0)", {
    space: "hsl",
    hue: "decreasing",
    outputSpace: "srgb"
  }),
  GREENS: new Color("rgb(255,255,255)").range("rgb(0,155,0)", {
    space: "lch",
    outputSpace: "srgb"
  }),
  JET: new Color("rgb(0,0,255)").range("rgb(255,0,0)", {
    space: "hsv",
    hue: "decreasing",
    outputSpace: "srgb",
    // FYI - the 'progression' option can be passed a function to change gradient progression
    progression: (p) => {
      const stretchedP = p ** 1.4;
      const manipulatedP = 0.5 * (1 - Math.cos(stretchedP * Math.PI));
      return manipulatedP;
    }
  })
};
var recolorColorStops = (colorStops, range = COLOR_RANGE_OPTIONS.GREEN_TO_RED) => {
  return colorStops.map((stop, index) => {
    const newColor = range(index / (colorStops.length - 1));
    return {
      stopValue: stop.stopValue,
      color: [
        Math.floor(newColor.srgb.r * 255),
        Math.floor(newColor.srgb.g * 255),
        Math.floor(newColor.srgb.b * 255)
      ]
    };
  });
};

// src/naming.ts
var getUniqueName = (name, existingRows) => {
  let uniqueName = name;
  if (existingRows.length > 0) {
    if (!existingRows.find((row) => row.name === name)) {
      return name;
    }
    const topDuplicateNumber = existingRows.reduce((top, row) => {
      const nameParts = row.name.split(`${name} `);
      if (nameParts.length > 1) {
        const dupeNumber = parseInt(nameParts[1].charAt(1));
        if (dupeNumber > top)
          return dupeNumber;
      }
      return top;
    }, 0);
    uniqueName = `${name} (${topDuplicateNumber + 1})`;
  }
  return uniqueName;
};
export {
  ALFALFA_HAT_PART_FIELDS,
  AnchorPointKey,
  ArrayDesignVersion,
  ArrayTypes,
  BERMUDA_HAT_PART_FIELDS,
  CELL_TECH_OPTIONS,
  COLOR_RANGE_OPTIONS,
  CONVERSION_OPTIONS,
  GEOFEATURE_DATA_SOURCES,
  GEOFEATURE_POLYGON_TYPES,
  GEOFEATURE_SHAPE_TYPES,
  GREEN_YELLOW_RED_COLOR_STOPS,
  HatType,
  INFINITY_SPLIT_AMOUNT,
  INVERTER_PART_FIELDS,
  MODULE_PART_FIELDS,
  POST_PART_FIELDS,
  PartType,
  SUPPORTED_CRS_DATA,
  SUPPORTED_CRS_TYPES,
  UNIT_TYPE,
  UNIT_TYPE_DISPLAY_TEXT,
  areAllDecimalsEqual,
  areDecimalsEqual,
  areVectorsEqual,
  ecefToEnu,
  ecefToGeodetic,
  enuToEcef,
  enuToGeodetic,
  geodeticToEcef,
  geodeticToEnu,
  getBboxCoordinates,
  getPartFieldsForPartType,
  getPartValidationError,
  getRecoloredSlopeData,
  getRectangularPrismCorners,
  getUTMCRSData,
  getUTMProj4,
  getUniqueName,
  getYupSchemaFromPartFields,
  mixColors,
  mountUpVectorsFromTilts,
  readUploadedFileAsDataURL,
  recolorColorStops,
  replacePartKeyWithDisplayText,
  transformPart,
  transformParts,
  updateQuaternionEnuOrigin
};
