import { useState, useEffect } from "react";
import { login } from "@planted-solar/login";
import App from "../App/App.js";

const Login = () => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        login()
            .then((user) => setUser(user))
            .catch((error) => alert(`Login error: ${error}`));
    }, []);

    return user ? <App /> : null;
};

export default Login;
