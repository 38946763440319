import mapboxgl from "mapbox-gl";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FaLayerGroup } from "react-icons/fa";
import styles from "./Map.module.css";

import {
    addMapSources,
    handleLayerToggleVisibility,
    toggleColorizedDSMLayer,
    toggleRGBLayer,
    toggleTerrariumLayer,
    toggleTerrariumRGBLayer,
} from "./mapManager";

const { REACT_APP_MAPBOX_ACCESS_TOKEN } = process.env;
mapboxgl.accessToken = REACT_APP_MAPBOX_ACCESS_TOKEN;
let map = null;
export function getMap() {
    return map;
}

function Map({ DSM }) {
    const mapContainer = useRef(null);
    const [isTerrainVisible, setTerrainVisibility] = useState(false);
    const [isTerrainRGBVisible, setTerrainRGBVisibility] = useState(false);
    const [isColorizedVisible, setColorizedVisibility] = useState(true);
    const [isRGBVisible, setIsRGBVisible] = useState(false);
    const [showLayerToggles, setShowLayerToggles] = useState(false);
    const [mapActive, setMapActive] = useState(false);
    const [laysersLoaded, setLayersLoaded] = useState(false);

    useEffect(() => {
        if (map && mapActive && laysersLoaded) {
            if (isColorizedVisible) {
                toggleColorizedDSMLayer(map, true);
            } else {
                toggleColorizedDSMLayer(map, false);
            }
            if (isTerrainVisible) {
                toggleTerrariumLayer(map, true);
            } else {
                toggleTerrariumLayer(map, false);
            }
            if (isTerrainRGBVisible) {
                toggleTerrariumRGBLayer(map, true);
            } else {
                toggleTerrariumRGBLayer(map, false);
            }
            if (isRGBVisible) {
                toggleRGBLayer(map, true);
            } else {
                toggleRGBLayer(map, false);
            }
        }
    }, [
        DSM,
        isColorizedVisible,
        isTerrainVisible,
        isTerrainRGBVisible,
        mapActive,
        laysersLoaded,
        isRGBVisible,
    ]);

    const addMapData = useCallback(() => {
        if (DSM.center_lon && DSM.center_lat && DSM.bbox != null) {
            // Extract bounding box corners
            const bounds = [
                [DSM.bbox[0][0], DSM.bbox[0][1]], // Southwest coordinates
                [DSM.bbox[2][0], DSM.bbox[2][1]], // Northeast coordinates
            ];
            // Fit map to the bounding box
            map.fitBounds(bounds, { padding: 20 });
            addMapSources(map, DSM);
            setLayersLoaded(true);
        }
    }, [DSM]);

    useEffect(() => {
        if (DSM && DSM.cog_location && mapContainer.current) {
            const initializeMap = ({ mapContainer }) => {
                const mapInstance = new mapboxgl.Map({
                    container: mapContainer.current,
                    style: "mapbox://styles/mapbox/satellite-streets-v11",
                    center: [
                        DSM.center_lon || -122.287864,
                        DSM.center_lat || 37.815379,
                    ],
                    zoom: DSM.zoom || 15,
                    maxZoom: 24,
                });
                mapInstance.on("error", (error) => {
                    // Hide "unsupported image type" errors which are triggered by our
                    // DSM manager tile server when no tile is available for a location
                    if (
                        error &&
                        error?.error?.message !==
                            "Could not load image because of The source image could not be decoded.. Please make sure to use a supported image type such as PNG or JPEG. Note that SVGs are not supported."
                    ) {
                        console.error(error);
                    }
                });
                mapInstance.on("load", function () {
                    map = mapInstance;
                    setMapActive(true);
                    addMapData();
                    map.triggerRepaint();
                });
                mapInstance.resize();
            };
            if (map === null) {
                initializeMap({ mapContainer });
            }
            return () => {
                map = null;
                setMapActive(false);
            };
        }
    }, [DSM, addMapData]);

    return (
        <div className={styles.mapContainer} ref={mapContainer}>
            <button
                className={styles.layersButton}
                onClick={() => handleLayerToggleVisibility(setShowLayerToggles)}
            >
                <FaLayerGroup />
            </button>

            {showLayerToggles && (
                <div className={styles.layersToggleOverlay}>
                    <div>
                        <input
                            type="checkbox"
                            id="colorizedToggle"
                            checked={isColorizedVisible}
                            onChange={() => setColorizedVisibility((prev) => !prev)}
                        />
                        <label htmlFor="colorizedToggle">Show Colorized Terrain</label>
                    </div>
                    {DSM.has_rgb && (
                        <div>
                            <input
                                type="checkbox"
                                id="rgbToggle"
                                checked={isRGBVisible}
                                onChange={() => setIsRGBVisible((prev) => !prev)}
                            />
                            <label htmlFor="rgbToggle">RGB Imagery</label>
                        </div>
                    )}
                    <div>
                        <input
                            type="checkbox"
                            id="terrainToggle"
                            checked={isTerrainVisible}
                            onChange={() => setTerrainVisibility((prev) => !prev)}
                        />
                        <label htmlFor="terrainToggle">Show Terrain</label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            id="terrainRGBToggle"
                            checked={isTerrainRGBVisible}
                            onChange={() => setTerrainRGBVisibility((prev) => !prev)}
                        />
                        <label htmlFor="terrainRGBToggle">Show Terrarium</label>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Map;
