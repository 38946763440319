import { FaExclamationTriangle } from "react-icons/fa";
import styles from "./dsmList.module.css";

function DSMList({ DSMs, onSelect }) {
    return (
        <div className={styles.dsmList}>
            {DSMs.map((DSM, index) => (
                <div
                    className={styles.dsmListItem}
                    key={index}
                    onClick={() => onSelect(DSM)}
                >
                    {DSM.name}{" "}
                    {DSM.status === "loading" && (
                        <div key={DSM.uuid} className={styles.spinner}></div>
                    )}
                    {DSM.status === "error" && <FaExclamationTriangle />}
                </div>
            ))}
        </div>
    );
}
export default DSMList;
