import styles from "./Button.module.css";

const Button = ({
    onClick,
    isSecondary,
    isSmall,
    isActive,
    isDisabled,
    isDestructive,
    children,
    className,
    style,
    Icon,
}) => {
    const onClickButton = () => {
        if (!isDisabled) {
            onClick();
        }
    };
    return (
        <div
            style={style}
            onClick={onClickButton}
            className={`
                ${styles.container}
                ${!isSecondary ? styles.isPrimary : ""}
                ${isSecondary ? styles.isSecondary : ""}
                ${isSmall ? styles.isSmall : ""}
                ${isActive ? styles.isActive : ""}
                ${isDisabled ? styles.isDisabled : ""}
                ${isDestructive ? styles.isDestructive : ""}
                ${className}
            `}
        >
            {Icon ?
                <Icon size={14} className={styles.icon} />
            :   null}
            {children}
        </div>
    );
};

export default Button;
