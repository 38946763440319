import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useEffect, useRef, useState, useCallback } from "react";
import { FaUpload } from "react-icons/fa";
import styles from "./App.module.css";

const { REACT_APP_MAPBOX_ACCESS_TOKEN } = process.env;
mapboxgl.accessToken = REACT_APP_MAPBOX_ACCESS_TOKEN;

export default function UploadDialog({
    open,
    setOpen,
    children,
    onClose,
    onUpload,
    uploadType,
}) {
    const dialogRef = useRef(null);
    const backdropRef = useRef(null);

    const handleBackdropClick = (event) => {
        if (backdropRef.current && dialogRef.current === event.target) {
            setOpen(false);
        }
    };

    useEffect(() => {
        if (open && dialogRef.current && !dialogRef.current.hasAttribute("open")) {
            dialogRef.current.showModal();
            window.addEventListener("click", handleBackdropClick);
        } else if (
            !open &&
            dialogRef.current &&
            dialogRef.current.hasAttribute("open")
        ) {
            dialogRef.current.close();
            window.removeEventListener("click", handleBackdropClick);
        }

        return () => {
            window.removeEventListener("click", handleBackdropClick);
        };
    }, [open, onClose, handleBackdropClick]);

    return (
        <div ref={backdropRef} className={styles.backdrop}>
            <dialog ref={dialogRef} className={styles.uploadDialog}>
                <UploadForm
                    onUpload={onUpload}
                    onClose={onClose}
                    uploadType={uploadType}
                />
            </dialog>
        </div>
    );
}

function UploadForm({ onUpload, onClose, uploadType }) {
    const [name, setName] = useState("");
    const [file, setFile] = useState(null);

    const handleUploadSubmit = useCallback(
        (e) => {
            e.preventDefault();
            if (file && name) {
                const ext = file.name.split(".").pop().toLowerCase();
                if (!uploadType.fileExtensions.find((validExt) => validExt === ext)) {
                    alert(
                        `Only ${uploadType.fileExtensions.map((file) => `.${file}`).join(" and")} files are supported`,
                    );
                    return;
                }
                onUpload(file, name);
            }
        },
        [uploadType, file, name],
    );
    function handleKeyDown(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
        }
    }

    return (
        <form onSubmit={handleUploadSubmit}>
            <h2 className={styles.dialogTitle}>Upload {uploadType.display}</h2>
            <label>
                Name:
                <input
                    type="text"
                    onKeyDown={handleKeyDown}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
            </label>
            <label>
                File:
                <input
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                    required
                    accept={uploadType.fileExtensions.map((ext) => `.${ext}`).join(",")}
                />
            </label>
            <p className={styles.uploadDialogText}>
                Make sure uploaded file has elevation in meters
            </p>
            <button type="submit" className={styles.uploadButton}>
                <FaUpload />
                Upload
            </button>
            <button type="button" onClick={onClose} className={styles.closeButton}>
                Close
            </button>
        </form>
    );
}
