import styles from "./Input.module.css";

const Input = ({ value, setValue, className, isSmall, error, ...props }) => {
    const onChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <input
            className={`
                ${styles.input}
                ${isSmall ? styles.isSmall : ""}
                ${error ? styles.error : ""}
                ${className}
            `}
            value={value}
            onChange={onChange}
            {...props}
        />
    );
};

export default Input;
