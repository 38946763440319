import { useState } from "react";
import Button from "../Button/Button";
import styles from "./Dropdown.module.css";
import ModalMenu from "../ModalMenu/ModalMenu";

const Dropdown = ({
    options,
    selectedIndex,
    setSelectedIndex,
    isSmall,
    isSecondary,
    className,
}) => {
    const [isActive, setIsActive] = useState(false);

    const modalOptions = options.map((option, index) => {
        return {
            onClick: () => setSelectedIndex(index),
            content: (
                <div
                    className={`
                    ${styles.option}
                    ${isSmall ? styles.isSmall : ""}
                `}
                >
                    {option.value}
                </div>
            ),
            isDisabled: option.isDisabled,
        };
    });

    return (
        <div
            className={`
            ${styles.container}
            ${className}
        `}
        >
            <Button
                className={styles.button}
                onClick={() => setIsActive(true)}
                isSecondary={isSecondary}
                isSmall={isSmall}
            >
                {options[selectedIndex].value}
            </Button>
            <div className={styles.modalContainer}>
                <ModalMenu
                    className={styles.modal}
                    options={modalOptions}
                    isVisible={isActive}
                    setIsVisible={setIsActive}
                    isSmall={isSmall}
                />
            </div>
        </div>
    );
};

export default Dropdown;
