import { fetchWithAuth } from "@planted-solar/login";
import toast from "react-hot-toast";

const REACT_APP_DSM_MANAGER_BACKEND_URI = process.env.REACT_APP_DSM_MANAGER_BACKEND_URI;

export const fetchDSMList = async () => {
    const endpoint = `${REACT_APP_DSM_MANAGER_BACKEND_URI}/list`;
    const response = await fetchWithAuth(endpoint);

    if (response.ok) {
        const data = await response.json();
        return data;
    } else {
        throw new Error("Error fetching DSM list");
    }
};

export const archiveDSM = async (uuid) => {
    const endpoint = `${REACT_APP_DSM_MANAGER_BACKEND_URI}/archive`;
    const response = await fetchWithAuth(endpoint, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ uuid }), // Sending the UUID of the DSM to be archived
    });

    if (response.ok) {
        const data = await response.json();
        return data;
    } else {
        throw new Error("Error archiving DSM");
    }
};

export const fetchDSMSignedDownloadUrl = async (cogLocation) => {
    const endpoint = `${REACT_APP_DSM_MANAGER_BACKEND_URI}/generate_signed_download_url?cog_location=${encodeURIComponent(cogLocation)}`;
    const response = await fetchWithAuth(endpoint, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (response.ok) {
        const data = await response.json();
        return data;
    } else {
        throw new Error("Error fetching DSM signed URL");
    }
};

export const fetchSlope = async (uuid) => {
    const endpoint = `${REACT_APP_DSM_MANAGER_BACKEND_URI}/slope_analysis/${uuid}`;
    const response = await fetchWithAuth(endpoint, {
        method: "GET",
        headers: {
            headers: { "Content-Type": "image/png" },
        },
    });
    if (response.ok) {
        const data = await response.blob();
        return data;
    } else {
        throw new Error("Error slope data.");
    }
};

export const uploadDSMFile = async (name, file) => {
    // Get signed upload URL
    let res = await fetchWithAuth(
        `${REACT_APP_DSM_MANAGER_BACKEND_URI}/dsms/signed_upload_url`,
    );
    if (!res.ok) {
        const error = await res.text();
        console.error(error);
        throw Error("Failed to get signed upload URL.");
    }
    const signedUploadUrl = await res.text();

    // Upload to signed URL
    res = await fetch(signedUploadUrl, {
        method: "PUT",
        headers: { "Content-Type": file.type },
        body: file,
    });
    if (!res.ok) {
        const error = await res.text();
        console.error(error);
        throw Error("Failed to upload to signed URL.");
    }

    // Process uploaded DSM
    const signedUrlPieces = signedUploadUrl.split(".tif")[0].split("/");
    const uuid = signedUrlPieces[signedUrlPieces.length - 1];
    res = await fetchWithAuth(`${REACT_APP_DSM_MANAGER_BACKEND_URI}/process_dsm`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name, uuid }),
    });

    if (!res.ok) {
        const error = await res.json();
        for (const errMessage of error.errors) {
            toast.error(errMessage);
        }
        console.error(error);
        throw Error("Failed to process uploaded DSM.");
    }
    return res.json();
};
export const uploadSlopeFile = async (uuid, file) => {
    // Get signed upload URL
    let res = await fetchWithAuth(
        `${REACT_APP_DSM_MANAGER_BACKEND_URI}/slope/signed_upload_url/${uuid}`,
    );
    if (!res.ok) {
        const error = await res.text();
        console.error(error);
        throw Error("Failed to get signed upload URL.");
    }
    const signedUploadUrl = await res.text();
    // Upload to signed URL
    res = await fetch(signedUploadUrl, {
        method: "PUT",
        headers: { "Content-Type": file.type },
        body: file,
    });
    if (!res.ok) {
        const error = await res.text();
        console.error(error);
        throw Error("Failed to upload to signed URL.");
    }
    // Process uploaded Slope
    res = await fetchWithAuth(`${REACT_APP_DSM_MANAGER_BACKEND_URI}/process_slope`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ uuid }),
    });
    if (!res.ok) {
        const error = await res.text();
        console.error(error);
        throw Error("Failed to process uploaded Slope.");
    }
    return res.json();
};
export const uploadContoursFile = async (name, file) => {
    // Get signed upload URL
    let res = await fetchWithAuth(
        `${REACT_APP_DSM_MANAGER_BACKEND_URI}/contours/signed_upload_url`,
    );
    if (!res.ok) {
        const error = await res.text();
        console.error(error);
        throw Error("Failed to get signed upload URL.");
    }
    const signedUploadUrl = await res.text();
    // Upload to signed URL
    res = await fetch(signedUploadUrl, {
        method: "PUT",
        headers: { "Content-Type": file.type },
        body: file,
    });
    if (!res.ok) {
        const error = await res.text();
        console.error(error);
        throw Error("Failed to upload to signed URL.");
    }
    // Process uploaded contours
    const breakpoint = new RegExp("/pps2-dsm((-dev)|(-backup)|(-test))?/");
    const signedUrlPieces = signedUploadUrl.split(breakpoint);
    const uuid = signedUrlPieces[signedUrlPieces.length - 1].split("/contours")[0];
    res = await fetchWithAuth(`${REACT_APP_DSM_MANAGER_BACKEND_URI}/process_contours`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name, uuid }),
    });
    if (!res.ok) {
        const error = await res.text();
        console.error(error);
        throw Error("Failed to process uploaded contours.");
    }
    return res.json();
};

export const uploadRGBFile = async (uuid, file) => {
    // Get signed upload URL
    let res = await fetchWithAuth(
        `${REACT_APP_DSM_MANAGER_BACKEND_URI}/rgb/signed_upload_url/${uuid}`,
    );
    if (!res.ok) {
        const error = await res.text();
        console.error(error);
        throw Error("Failed to get signed upload URL.");
    }
    const signedUploadUrl = await res.text();
    // Upload to signed URL
    res = await fetch(signedUploadUrl, {
        method: "PUT",
        headers: { "Content-Type": file.type },
        body: file,
    });
    if (!res.ok) {
        const error = await res.text();
        console.error(error);
        throw Error("Failed to upload to signed URL.");
    }
    // Process uploaded RGB
    res = await fetchWithAuth(`${REACT_APP_DSM_MANAGER_BACKEND_URI}/process_rgb`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ uuid }),
    });
    if (!res.ok) {
        const error = await res.text();
        console.error(error);
        throw Error("Failed to process uploaded DSM.");
    }
    return res.json();
};
export const reprocessDSM = async (name, uuid) => {
    let res = await fetchWithAuth(`${REACT_APP_DSM_MANAGER_BACKEND_URI}/process_dsm`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ uuid, reprocess: true }),
    });
    if (!res.ok) {
        const error = await res.text();
        console.error(error);
        throw Error("Failed to process uploaded DSM.");
    }
    return res.json();
};
