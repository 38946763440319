import { initializeApp } from "firebase/app";
import {
    GoogleAuthProvider,
    getAuth,
    inMemoryPersistence,
    setPersistence,
    signInWithCustomToken,
    signInWithPopup,
} from "firebase/auth";

const REACT_APP_FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
const REACT_APP_FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
const USER_MANAGER_BACKEND_URI = process.env.REACT_APP_USER_MANAGER_BACKEND_URI;

const DEFAULT_OPTIONS = {
    skipLoginBlocker: false,
    userManagerBackendUri: USER_MANAGER_BACKEND_URI,
};

let loggedInUser = null;
let isLoginBlockerActive = false;

// Login workflow
// Step 1. Check if user is logged in with Firebase
//     * If yes, refresh session cookie, return user
//     * If no, proceed to step 2
// Step 2. Try to trade session cookie for auth token
//     * If success, login with firebase, refresh session cookie, return user
//     * If failure, proceed to step 3
// Step 3. Show Firebase login screen
//     * Upon login, refresh session cookie, return user
export const login = async (options = DEFAULT_OPTIONS) => {
    await initFirebase();
    loggedInUser = getFirebaseUser();
    if (!loggedInUser) {
        loggedInUser = await loginWithSessionCookie(options);
    }
    if (!loggedInUser) {
        loggedInUser = await loginWithFirebase(options);
    }
    if (loggedInUser) {
        const emailDomain = loggedInUser.email.split("@")[1];
        if (emailDomain === "plantedsolar.com") {
            await refreshCookie(options, loggedInUser);
            return loggedInUser;
        } else {
            await logout(options);
            alert("Please refresh and log in with a plantedsolar.com email.");
        }
    }
};

// Logout workflow
// * Nullify loggedInUser
// * Sign out of Firebase
// * Clear session cookie (via logout endpoint)
export const logout = async (options) => {
    loggedInUser = null;
    const endpoint = `${options.userManagerBackendUri}/auth/session_logout`;
    await fetch(endpoint, {
        method: "post",
        credentials: "include",
    });
    await getAuth().signOut();
    window.location.reload();
};

// Fetch with auth header containing user's Firebase ID token
export const fetchWithAuth = async (url, options = {}) => {
    if (!loggedInUser) {
        throw Error("Cannot fetch with auth - user is not logged in.");
    }
    const idToken = await loggedInUser.getIdToken(true); // the extra 'true' forces a refresh of the token as well
    options.headers = options.headers || {};
    options.headers.Authorization = `Bearer ${idToken}`;
    return fetch(url, options);
};

const initFirebase = async () => {
    initializeApp({
        apiKey: REACT_APP_FIREBASE_API_KEY,
        authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
    });
    await setPersistence(getAuth(), inMemoryPersistence);
    return new Promise((resolve) => {
        getAuth().onAuthStateChanged(() => resolve());
    });
};

const getFirebaseUser = () => {
    return getAuth().currentUser;
};

const loginWithSessionCookie = async (options) => {
    try {
        const authToken = await getAuthToken(options);
        const userCredential = await signInWithCustomToken(getAuth(), authToken);
        return userCredential.user;
    } catch (e) {
        return null;
    }
};

const getAuthToken = async (options) => {
    const endpoint = `${options.userManagerBackendUri}/auth/token`;
    const res = await fetch(endpoint, {
        method: "post",
        credentials: "include",
    });
    if (res.status === 401) {
        throw Error("Session cookie missing or expired.");
    }
    const { authToken } = await res.json();
    return authToken;
};

const loginWithFirebase = (options) => {
    if (options?.skipLoginBlocker) {
        return loginWithFirebasePopup();
    } else {
        return loginWithFirebasePopupWithLoginBlocker();
    }
};

const loginWithFirebasePopup = async () => {
    try {
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({ prompt: "select_account" });
        const userCredentials = await signInWithPopup(getAuth(), provider);
        return userCredentials?.user;
    } catch (error) {
        console.error("loginWithFirebasePopup", error);
        return null;
    }
};

const loginWithFirebasePopupWithLoginBlocker = () => {
    if (!isLoginBlockerActive) {
        isLoginBlockerActive = true;
        return new Promise((resolve) => {
            const loginBlocker = document.createElement("div");
            loginBlocker.innerHTML = `
                <div style="
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 100;
                    background-color: white;
                ">
                    <div style="
                        cursor: pointer;
                        padding: 20px;
                        font-size: 16px;
                    ">
                        Log in
                    </div>
                </div>
            `;
            document.querySelector("body").append(loginBlocker);
            loginBlocker.addEventListener("click", async () => {
                const user = await loginWithFirebasePopup();
                resolve(user);
                loginBlocker.remove();
                isLoginBlockerActive = false;
            });
        });
    }
};

const refreshCookie = async (options, user) => {
    const idToken = await user.getIdToken();
    const endpoint = `${options.userManagerBackendUri}/auth/session_login`;
    await fetch(endpoint, {
        method: "post",
        body: JSON.stringify({ idToken }),
        headers: { "Content-Type": "application/json" },
        credentials: "include",
    });
};
