import {
    COLOR_RANGE_OPTIONS,
    GREEN_YELLOW_RED_COLOR_STOPS,
    INFINITY_SPLIT_AMOUNT,
    getBboxCoordinates,
    getRecoloredSlopeData,
    mixColors,
    recolorColorStops,
} from "@planted-solar/js-utils";
import { useCallback, useEffect, useState } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import Button from "../../../sharedComponents/Button/Button";
import Dropdown from "../../../sharedComponents/Dropdown/Dropdown";
import Input from "../../../sharedComponents/Input/Input";
import styles from "./SlopeAnalysis.module.css";

const getTitleCaseString = (input) => {
    return input.replaceAll("_", " ").replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

const SlopeAnalysis = ({ map, DSM, slopeImageData }) => {
    const [error, setError] = useState(null);
    const [recoloredImage, setRecoloredImage] = useState(null);
    const [colorStops, setColorStops] = useState(GREEN_YELLOW_RED_COLOR_STOPS);
    const [interpolateSlope, setInterpolateSlope] = useState(false);
    const [selectedColorRangeIndex, setSelectedColorRangeIndex] = useState(0);

    const setRecoloredImageData = async (imageData, colorStops, interpolateSlope) => {
        const { dataURL } = await getRecoloredSlopeData(imageData, {
            colorStops,
            interpolateSlope,
            bbox: DSM.bbox.slice(1),
            clippingPolygon: null,
        });
        setRecoloredImage(dataURL);
    };
    useEffect(() => {
        setRecoloredImageData(slopeImageData, colorStops, interpolateSlope);
    }, [slopeImageData, colorStops, interpolateSlope, DSM]);

    useEffect(() => {
        if (map) {
            if (map.getLayer("slope-image-layer")) {
                map.removeLayer("slope-image-layer");
            }
            if (map.getSource("slope-image")) {
                map.removeSource("slope-image");
            }
            if (recoloredImage) {
                // Ensure the map instance exists
                // Add the image source and layer to the map
                if (!map.getSource("slope-image") && DSM.bbox != null) {
                    map.addSource("slope-image", {
                        type: "image",
                        url: recoloredImage,
                        coordinates: [
                            DSM.bbox[1], // Bottom right
                            DSM.bbox[2], // Top right
                            DSM.bbox[3], // Top left
                            DSM.bbox[0], // Bottom left
                        ],
                    });
                    map.addLayer({
                        id: "slope-image-layer",
                        type: "raster",
                        source: "slope-image",
                        paint: {
                            // use nearest neighbor to avoid blurring pixels
                            "raster-resampling": "nearest",
                        },
                    });
                }
            }
        }
    }, [recoloredImage, map, DSM]);

    const updateColorStops = (value, index) => {
        const parsedValue = isNaN(parseFloat(value)) ? value : parseFloat(value);
        setColorStops((prev) => {
            if (prev[index].stopValue !== parsedValue) {
                let newStops = structuredClone(prev);
                newStops[index].stopValue = parsedValue;
                return newStops;
            }
            return prev;
        });
        const upperLimit =
            index === colorStops.length - 1 ?
                Infinity
            :   colorStops[index + 1].stopValue;
        const lowerLimit = index === 0 ? 0 : colorStops[index - 1].stopValue;
        if (parsedValue < lowerLimit || parsedValue > upperLimit) {
            setError(
                "Values should be ordered from high to low from top to bottom to get an accurate image.",
            );
        } else {
            setError(null);
        }
    };

    const addColorStop = (index) => {
        setColorStops((prev) => {
            let newStops = structuredClone(prev);
            const lowerStopValue = index !== 0 ? prev[index - 1].stopValue : 0;
            const upperStopValue = prev[index].stopValue;
            const topColor = prev[index].color;
            const newColor =
                index !== 0 ?
                    mixColors(topColor, prev[index - 1].color)
                :   mixColors(topColor);
            // For the max val, we can't split evenly between the lower bound and infinity,
            // so just bump it up by a magic number
            if (upperStopValue === Infinity) {
                newStops.splice(index, 0, {
                    stopValue: lowerStopValue + INFINITY_SPLIT_AMOUNT,
                    color: newColor,
                });
            } else {
                // Split surrounding bounds
                newStops.splice(index, 0, {
                    stopValue: (lowerStopValue + upperStopValue) / 2.0,
                    color: newColor,
                });
            }
            return newStops;
        });
    };

    const removeColorStop = (index) => {
        setColorStops((prev) => {
            let newStops = structuredClone(prev);
            newStops.splice(index, 1);
            // ensure last item has stop set to Infinity
            newStops[newStops.length - 1].stopValue = Infinity;
            return newStops;
        });
    };

    const recolor = useCallback(() => {
        const colorRangeOptions = Object.keys(COLOR_RANGE_OPTIONS);
        const colorRangeSelection = colorRangeOptions[selectedColorRangeIndex];
        setColorStops((prev) =>
            recolorColorStops(prev, COLOR_RANGE_OPTIONS[colorRangeSelection]),
        );
    }, [selectedColorRangeIndex, setColorStops]);

    return (
        <div className={styles.slopeAnalysis}>
            <div className={styles.slopeAnalysisTitle}>Slope analysis</div>
            <div className={styles.interpolateRow} data-tooltip-id="interpolate">
                <input
                    type="checkbox"
                    id="interpolateSlope"
                    className={styles.interpolateSlopeCheckBox}
                    checked={interpolateSlope}
                    value={interpolateSlope}
                    onChange={() => setInterpolateSlope((prevState) => !prevState)}
                />
                <label htmlFor="interpolateSlope" className={styles.interpolateLabel}>
                    Interpolate Slope
                </label>
            </div>
            <Tooltip id="interpolate" className={styles.interpolateTooltip}>
                Turn on if you want to see a smoother gradient from min to max slope
            </Tooltip>
            <div className={styles.slopeAnalysisColorStops}>
                {colorStops.map((stop, index) => {
                    const stopMin = index === 0 ? 0 : colorStops[index - 1].stopValue;
                    const stopMax =
                        index === colorStops.length - 1 ? "∞" : stop.stopValue;
                    return (
                        <div key={`stop-${index}`} className={styles.slopeAnalysisRow}>
                            <div className={styles.innerSlopeAnalysisRow}>
                                <div
                                    className={styles.colorSquare}
                                    style={{
                                        backgroundColor: `rgb(${stop.color[0]}, ${stop.color[1]}, ${stop.color[2]})`,
                                    }}
                                ></div>
                                <div className={styles.slopeAnalysisButtons}>
                                    <Button
                                        onClick={() => addColorStop(index)}
                                        isSmall
                                        isSecondary
                                        Icon={FaPlus}
                                        className={styles.addColorStop}
                                    />
                                </div>
                                <label className={styles.slopeAnalysisLabel}>
                                    Step {index + 1}
                                    <br />
                                    {!interpolateSlope && `(${stopMin}° - ${stopMax}°)`}
                                </label>
                                <Button
                                    onClick={() => removeColorStop(index)}
                                    isSmall
                                    isSecondary
                                    Icon={FaTrash}
                                    className={styles.removeColorStop}
                                />
                            </div>

                            {/* Don't show last input, and only show min/max for interpolation */}
                            {index !== colorStops.length - 1 &&
                                !(
                                    interpolateSlope &&
                                    index > 0 &&
                                    index < colorStops.length - 2
                                ) && (
                                    <Input
                                        type="number"
                                        value={stop.stopValue}
                                        setValue={(value) =>
                                            updateColorStops(value, index)
                                        }
                                        className={styles.slopeInput}
                                        isSmall
                                    />
                                )}
                        </div>
                    );
                })}
            </div>
            {error && <p className={styles.errorMessage}>{error}</p>}
            <label className={styles.colorRangeLabel}>Color Range For Recoloring</label>
            <Dropdown
                options={Object.keys(COLOR_RANGE_OPTIONS).map((key) => ({
                    value: getTitleCaseString(key),
                }))}
                selectedIndex={selectedColorRangeIndex}
                setSelectedIndex={setSelectedColorRangeIndex}
                isSecondary
                className={styles.colorRangeDropdown}
            />
            <Button onClick={recolor} className={styles.recolorButton}>
                Recolor
            </Button>
        </div>
    );
};

export default SlopeAnalysis;
