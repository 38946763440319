import styles from "./ModalMenu.module.css";

const ModalMenu = ({ className, options, isVisible, setIsVisible, isSmall }) => {
    const onClickOption = (option) => () => {
        if (!option.isDisabled) {
            option.onClick();
            setIsVisible(false);
        }
    };

    const onClickBackground = () => {
        setIsVisible(false);
    };

    if (!isVisible) {
        return null;
    }
    return (
        <>
            <div
                className={`
                ${styles.modal}
                ${isSmall ? styles.isSmall : ""}
                ${className}
            `}
            >
                {options.map((option, index) => (
                    <div
                        key={index}
                        className={`
                            ${styles.option}
                            ${option.isDisabled ? styles.isDisabled : ""}
                        `}
                        onClick={onClickOption(option)}
                    >
                        {option.content}
                    </div>
                ))}
            </div>
            <div className={styles.background} onClick={onClickBackground}></div>
        </>
    );
};

export default ModalMenu;
