const REACT_APP_DSM_MANAGER_BACKEND_URI = process.env.REACT_APP_DSM_MANAGER_BACKEND_URI;

export const handleLayerToggleVisibility = (setShowLayerToggles) => {
    setShowLayerToggles((prev) => !prev);
};

export const removeMapSources = (map) => {
    if (map) {
        if (map.getLayer("colorized-dsm-layer")) {
            map.removeLayer("colorized-dsm-layer");
        }
        map.setTerrain(null);
        if (map.getLayer("terrarium-rgb-layer")) {
            map.removeLayer("terrarium-rgb-layer");
        }
        if (map.getSource("colorized-dsm-tiles")) {
            map.removeSource("colorized-dsm-tiles");
        }
        if (map.getSource("terrarium")) {
            map.removeSource("terrarium");
        }
        if (map.getSource("terrarium-rgb")) {
            map.removeSource("terrarium-rgb");
        }
        if (map.getSource("rgb-layer")) {
            map.removeSource("rgb-layer");
        }
    }
};

export function toggleLayer(id, map, shouldAdd) {
    if (shouldAdd) {
        map.setLayoutProperty(id, "visibility", "visible");
    } else {
        map.setLayoutProperty(id, "visibility", "none");
    }
}

export const toggleTerrariumRGBLayer = (map, shouldAdd) => {
    if (!map) return;
    toggleLayer("terrarium-rgb-layer", map, shouldAdd);
};
export const toggleRGBLayer = (map, shouldAdd) => {
    if (!map) return;
    toggleLayer("rgb-layer", map, shouldAdd);
};
export const toggleColorizedDSMLayer = (map, shouldAdd) => {
    if (!map) return;
    toggleLayer("colorized-dsm-layer", map, shouldAdd);
};
export const toggleTerrariumLayer = (map, shouldAdd) => {
    if (!map) return;
    if (shouldAdd) {
        if (!map.getLayer("terrarium")) {
            map.setTerrain({
                source: "terrarium",
                exaggeration: 1,
            });
        }
    } else {
        map.setTerrain(null);
    }
};

export const addMapSources = (map, DSM) => {
    if (!map.getSource("colorized-dsm-tiles")) {
        map.addSource("colorized-dsm-tiles", {
            type: "raster",
            tiles: [
                `${REACT_APP_DSM_MANAGER_BACKEND_URI}/color_tiles/${DSM.uuid}/{z}/{x}/{y}.png`,
            ],
            tileSize: 256,
        });
        if (!map.getLayer("colorized-dsm-layer")) {
            map.addLayer({
                id: "colorized-dsm-layer",
                type: "raster",
                source: "colorized-dsm-tiles",
                minzoom: 0,
                maxzoom: 24,
                layout: {
                    visibility: "visible",
                },
            });
        }
    }
    if (!map.getSource("terrarium")) {
        map.addSource("terrarium", {
            type: "raster-dem",
            tiles: [
                `${REACT_APP_DSM_MANAGER_BACKEND_URI}/terrarium_tiles/${DSM.uuid}/{z}/{x}/{y}.png`,
            ],
            tileSize: 256,
            encoding: "terrarium", // Assuming your tiles are in "terrarium" format, otherwise use "mapbox"
        });
    }
    if (!map.getSource("terrarium-rgb")) {
        map.addSource("terrarium-rgb", {
            type: "raster",
            tiles: [
                `${REACT_APP_DSM_MANAGER_BACKEND_URI}/terrarium_tiles/${DSM.uuid}/{z}/{x}/{y}.png`,
            ],
            tileSize: 256,
        });
        if (!map.getLayer("terrarium-rgb-layer")) {
            map.addLayer({
                id: "terrarium-rgb-layer",
                type: "raster",
                source: "terrarium-rgb",
                minzoom: 0,
                maxzoom: 22,
                layout: {
                    visibility: "none",
                },
            });
        }
    }
    // Add RGB imagery layer
    if (!map.getSource("rgb-layer")) {
        map.addSource("rgb-layer", {
            type: "raster",
            tiles: [
                `${REACT_APP_DSM_MANAGER_BACKEND_URI}/rgb_tiles/${DSM.uuid}/{z}/{x}/{y}.png`,
            ],
            tileSize: 256,
        });
        if (!map.getLayer("rgb-layer")) {
            map.addLayer({
                id: "rgb-layer",
                type: "raster",
                source: "rgb-layer",
                minzoom: 0,
                maxzoom: 24,
                layout: {
                    visibility: "none",
                },
            });
        }
    }
};
