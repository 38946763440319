import { reprocessDSM } from "../../../api.js";

const reprocessAllDSMs = async (DSMs, setDSMs) => {
    for (let DSM of DSMs) {
        console.log("reprocessing", DSM);
        try {
            DSM.status = "loading";
            let updatedDSMs = DSMs.map((existingDSM) =>
                existingDSM.uuid === DSM.uuid ? DSM : existingDSM,
            );
            setDSMs(updatedDSMs);
            console.log("Starting reprocess for:", DSM.name);
            await reprocessDSM(DSM.name, DSM.uuid);
            console.log("Finished reprocess for:", DSM.name);
            DSM.status = "done";
            updatedDSMs = DSMs.map((existingDSM) =>
                existingDSM.uuid === DSM.uuid ? DSM : existingDSM,
            );
            setDSMs(updatedDSMs);
        } catch (error) {
            console.error("Error during reprocessing:", error);
        }
    }
};

export { reprocessAllDSMs };
