const {
    REACT_APP_DSM_MANAGER_FRONTEND_URI,
    REACT_APP_PARTS_MANAGER_FRONTEND_URI,
    REACT_APP_PROJECT_MANAGER_FRONTEND_URI,
} = process.env;

const SVG_ICONS = {
    PLANTED_LOGO:
        '<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 360"><path d="m15.583 185.257 82.88-82.88 50.233 50.233-82.88 82.88zm86.993-87.014 82.88-82.88 50.233 50.233-82.88 82.88z" style="fill:#00e8fc"/><path d="m69.972 239.646 82.88-82.88L203.085 207l-82.88 82.88zm87-87.006 82.88-82.88 50.233 50.233-82.88 82.88z" style="fill:#ff6734"/><path d="m124.315 293.987 82.88-82.88 50.233 50.233-82.88 82.88zm87.003-87.004 82.88-82.88 50.233 50.233-82.88 82.88z" style="fill:#ffca00"/></svg>',
    MENU: '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg>',
    PARTS_MANAGER:
        '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M78.6 5C69.1-2.4 55.6-1.5 47 7L7 47c-8.5 8.5-9.4 22-2.1 31.6l80 104c4.5 5.9 11.6 9.4 19 9.4h54.1l109 109c-14.7 29-10 65.4 14.3 89.6l112 112c12.5 12.5 32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3l-112-112c-24.2-24.2-60.6-29-89.6-14.3l-109-109V104c0-7.5-3.5-14.5-9.4-19L78.6 5zM19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L233.7 374.3c-7.8-20.9-9-43.6-3.6-65.1l-61.7-61.7L19.9 396.1zM512 144c0-10.5-1.1-20.7-3.2-30.5c-2.4-11.2-16.1-14.1-24.2-6l-63.9 63.9c-3 3-7.1 4.7-11.3 4.7H352c-8.8 0-16-7.2-16-16V102.6c0-4.2 1.7-8.3 4.7-11.3l63.9-63.9c8.1-8.1 5.2-21.8-6-24.2C388.7 1.1 378.5 0 368 0C288.5 0 224 64.5 224 144l0 .8 85.3 85.3c36-9.1 75.8 .5 104 28.7L429 274.5c49-23 83-72.8 83-130.5zM56 432a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z"/></svg>',
    DSM_MANAGER:
        '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M384 476.1L192 421.2V35.9L384 90.8V476.1zm32-1.2V88.4L543.1 37.5c15.8-6.3 32.9 5.3 32.9 22.3V394.6c0 9.8-6 18.6-15.1 22.3L416 474.8zM15.1 95.1L160 37.2V423.6L32.9 474.5C17.1 480.8 0 469.2 0 452.2V117.4c0-9.8 6-18.6 15.1-22.3z"/></svg>',
};

export const PLANTED_APP_NAMES = {
    POWER_PLANTER: "Power Planter",
    PROJECT_MANAGER: "Project Manager",
    DSM_MANAGER: "DSM Manager",
    PARTS_MANAGER: "Parts Manager",
};

const LINK_DATA = [
    {
        name: PLANTED_APP_NAMES.DSM_MANAGER,
        icon: SVG_ICONS.DSM_MANAGER,
        url: REACT_APP_DSM_MANAGER_FRONTEND_URI,
    },
    {
        name: PLANTED_APP_NAMES.PARTS_MANAGER,
        icon: SVG_ICONS.PARTS_MANAGER,
        url: REACT_APP_PARTS_MANAGER_FRONTEND_URI,
    },
];

const HTML = `
    <div class="outer-container">
        <div class="container">
            <a href="${REACT_APP_PROJECT_MANAGER_FRONTEND_URI}">
                <div class="logo">
                    ${SVG_ICONS.PLANTED_LOGO}
                </div>
            </a>
            <div class="menu-button">
                ${SVG_ICONS.MENU}
            </div>
            <div class="text">
                <b>Planted Solar&nbsp;</b>|&nbsp;
            </div>
            <div class="children">
                <slot></slot>
            </div>
        </div>
        <div class="modal">
            <div class="modal-background"></div>
            <div class="modal-foreground">
            </div>
        </div>
    </div>
`;

const CSS = `
    * {
        font-family: Poppins, sans-serif;
    }

    .outer-container {
        position: relative;
    }

    .container {
        background-color: #15358e;
        display: flex;
        align-items: center;
        padding: 0 20px;
        height: 40px;
        color: white;
        font-size: 16px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 5px
    }

    .logo {
        width: 24px;
        height: 24px;
        margin-right: 15px;
    }

    .menu-button {
        fill: white;
        display: flex;
        padding: 5px;
        margin-right: 15px;
        border-radius: 2px;
        cursor: pointer;
    }

    .menu-button:hover {
        background-color: #0C2874;
    }

    .text {
        display: flex;
        user-select: none;
    }

    .children {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .modal {
        display: none;
        position: absolute;
        left: 45px;
    }

    .modal-background {
        z-index: 1000;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    .modal-foreground {
        z-index: 1001;
        width: 190px;
        position: absolute;
        border-radius: 2px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px;
        background-color: white;
        font-size: 14px;
        font-weight: bold;
    }

    .modal-foreground > a {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        border-radius: 2px;
        text-decoration: none;
        color: black;
        user-select: none;
    }

    .modal-foreground > a:hover {
        background-color: #eee;
    }

    .modal-foreground > a > svg {
        margin-right: 10px
    }
`;

const template = document.createElement("template");
template.innerHTML = `<style>${CSS}</style>${HTML}`;

class PlantedSolarHeader extends HTMLElement {
    constructor() {
        super();
        const shadowRoot = this.attachShadow({ mode: "open" });
        shadowRoot.appendChild(template.content.cloneNode(true));
    }

    connectedCallback() {
        // Add application title
        const text = this.shadowRoot.querySelector(".text");
        const titleElement = document.createElement("div");
        titleElement.innerText = this.getAttribute("title");
        text.appendChild(titleElement);

        // Add menu items
        const modalForeground = this.shadowRoot.querySelector(".modal-foreground");
        for (const { name, icon, url } of LINK_DATA) {
            modalForeground.innerHTML += `
                <a href="${url}">
                    ${icon}
                    ${name}
                </a>
            `;
        }

        // Add modal toggling
        const menuButton = this.shadowRoot.querySelector(".menu-button");
        const modal = this.shadowRoot.querySelector(".modal");
        const modalBackground = this.shadowRoot.querySelector(".modal-background");
        menuButton.addEventListener("click", () => {
            modal.style.display = "block";
        });
        modalBackground.addEventListener("click", () => {
            modal.style.display = "none";
        });
    }
}

window.customElements.define("planted-solar-header", PlantedSolarHeader);
