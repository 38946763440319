import { PLANTED_APP_NAMES } from "@planted-solar/web-components";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useEffect, useRef, useState, useCallback } from "react";
import styles from "./App.module.css";
import { Toaster } from "react-hot-toast";
import {
    fetchDSMList,
    reprocessDSM,
    uploadDSMFile,
    uploadContoursFile,
} from "../../../api.js";
import UploadDialog from "./UploadDialog.js";
import DSMDetails from "./dsmDetails/DsmDetails.js";
import DSMList from "./dsmList/dsmList.js";
import { reprocessAllDSMs } from "./reprocessLogic";

const { REACT_APP_MAPBOX_ACCESS_TOKEN } = process.env;
mapboxgl.accessToken = REACT_APP_MAPBOX_ACCESS_TOKEN;

// TODO: break out to constants file
const UPLOAD_TYPES = {
    DSM: {
        fileExtensions: ["tif", "tiff"],
        display: "DSM",
    },
    CONTOURS: {
        fileExtensions: ["zip"], // validation of files in zip will be handled by BE
        display: "Contours",
    },
};

const App = () => {
    const [selectedDSM, setSelectedDSM] = useState(null);
    const [open, setOpen] = useState(false);
    const [uploadType, setUploadType] = useState(UPLOAD_TYPES.DSM);
    const [DSMs, setDSMs] = useState([]);
    const [showReprocessAll, setShowReprocessAll] = useState(false);
    const reprocessAllBtnRef = useRef(null);
    const existingDSMs = useRef();

    const onUploadDSMClick = () => {
        setUploadType(UPLOAD_TYPES.DSM);
        setOpen(true);
    };

    const onUploadContoursClick = () => {
        setUploadType(UPLOAD_TYPES.CONTOURS);
        setOpen(true);
    };

    useEffect(() => {
        const fetchDSMs = async () => {
            const response = await fetchDSMList();
            if (response && response.dsm_list) {
                setDSMs(response.dsm_list);
                existingDSMs.current = response.dsm_list;
            } else {
                console.error("Unexpected response from server:", response);
            }
        };

        fetchDSMs();

        const keyDownListener = (event) => {
            if (event.shiftKey && event.code === "KeyR") {
                setShowReprocessAll(!showReprocessAll);
            }
        };
        document.addEventListener("keydown", keyDownListener);
        // Clean up event listeners on component unmount
        return () => {
            document.removeEventListener("keydown", keyDownListener);
        };
    }, []);
    useEffect(() => {
        existingDSMs.current = DSMs;
    }, [DSMs]);

    const handleReprocess = async (DSM) => {
        try {
            DSM.status = "loading";
            const updatedDSMs = DSMs.map((existingdsm) =>
                existingdsm.uuid === DSM.uuid ? DSM : existingdsm,
            );
            setDSMs(updatedDSMs);
            const response = await reprocessDSM(DSM.name, DSM.uuid);
            console.log(response);
            console.log("stopping");
            refreshDSMList();
        } catch (error) {
            console.error("Error during reprocessing:", error);
        }
    };

    const handleUpload = useCallback(
        async (file, name) => {
            const newDSM = {
                name,
                status: "loading",
            };
            setDSMs([newDSM, ...DSMs]);
            setOpen(false);

            try {
                if (uploadType === UPLOAD_TYPES.DSM) {
                    const response = await uploadDSMFile(name, file);
                    newDSM.uuid = response.uuid;
                } else if (uploadType === UPLOAD_TYPES.CONTOURS) {
                    const response = await uploadContoursFile(name, file);
                    newDSM.uuid = response.uuid;
                }
            } catch (error) {
                console.error(error);
                newDSM.error = true; // if there's an error, mark this DSM as failed
            }
            refreshDSMList();
        },
        [uploadType],
    );

    const reprocessAllClick = async () => {
        let verify = window.confirm("Are you sure you want to reprocess all DSMs?");
        if (verify) {
            await reprocessAllDSMs(existingDSMs.current, setDSMs);
            refreshDSMList();
        }
        alert("All DSMs finished reprocessing!");
    };

    const refreshDSMList = async () => {
        try {
            const response = await fetchDSMList();
            if (response && response.dsm_list) {
                const updatedDSMs = response.dsm_list.map((dsm) => dsm);
                setDSMs(updatedDSMs);
            } else {
                console.error("Unexpected response from server:", response);
            }
        } catch (error) {
            console.error("Error fetching DSM list:", error);
        }
    };

    return (
        <div className={styles.container}>
            <planted-solar-header title={PLANTED_APP_NAMES.DSM_MANAGER} />
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.uploadButtons}>
                        <button
                            onClick={onUploadDSMClick}
                            className={[styles.uploadDSMButton, styles.upload].join(
                                " ",
                            )}
                        >
                            Upload DSM
                        </button>
                        <button
                            onClick={onUploadContoursClick}
                            className={[styles.uploadDSMButton, styles.upload].join(
                                " ",
                            )}
                        >
                            Upload Contours
                        </button>
                        {showReprocessAll && (
                            <button
                                onClick={reprocessAllClick}
                                className={[
                                    styles.reprocessAllButton,
                                    styles.upload,
                                ].join(" ")}
                            >
                                Reprocess All DSMs
                            </button>
                        )}
                    </div>
                    <DSMDetails
                        className={styles.dsmDetails}
                        DSM={selectedDSM}
                        handleReprocess={handleReprocess}
                    />
                    <DSMList
                        className={styles.dsmList}
                        DSMs={DSMs}
                        onSelect={setSelectedDSM}
                    />
                </div>

                <UploadDialog
                    open={open}
                    setOpen={setOpen}
                    onClose={() => setOpen(false)}
                    onUpload={handleUpload}
                    uploadType={uploadType}
                ></UploadDialog>
            </div>
            <Toaster />
        </div>
    );
};

export default App;
